import React, { useMemo } from 'react'
import { Header } from 'tabler-react'
import Select from 'react-select'

interface UserCalendarEventTypeFilterProps {
  eventTypes: any
  activeFilter: number[]
  onChangeProp: (id: number[]) => void
}

const UserCalendarEventTypeFilter = ({
  eventTypes,
  activeFilter,
  onChangeProp
}: UserCalendarEventTypeFilterProps) => {
  const options = useMemo(() => {
    return eventTypes.map((et) => ({ label: et.name, value: et.id }))
  }, [eventTypes])

  return (
    <>
      <Header.H4>Event Types</Header.H4>
      <Select
        isMulti
        isClearable={true}
        onChange={(slected) => {
          if (slected) onChangeProp(slected.map((s) => Number(s.value)) || [])
          else onChangeProp([])
        }}
        options={options}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
    </>
  )
}

export default UserCalendarEventTypeFilter
