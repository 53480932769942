import React, { useContext, useRef, useState } from 'react'
import useReactRouter from 'use-react-router'
import { Box } from '@material-ui/core'
import autoCompleteStyles from '../Students/Forms/autocompleteStyles'
import { Button, Form, Tag } from 'tabler-react'
import { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import useGetCurrentUserType from '../../modules/common/hooks/useGetCurrentUserType'
import StudentCreateFromSearch from '../Students/Modal/StudentCreateFromSearch'
import { useAutoCompleteAdminSearch } from '../../hooks/useAutoCompleteAdminSearch'
import { GET_ADMIN_SEARCH_QUERY } from '../../graphql/GET_ADMIN_SEARCH_QUERY'
import AddCoachFromSearch from '../Coaches/Forms/AddCoachFromSearch'
import { Types } from '../../types/graphql'
import { UserStoreContext } from '../../contexts/userStoreContext'
import { useLazyQuery } from '@apollo/client'
import { GET_STUDENT_BY_ID } from '../../graphql/GET_STUDENT_BY_ID'
import { GET_COACH_BY_ID } from '../../graphql/GET_COACH_BY_ID'
import { useRootStore } from '../../hooks'

interface AdminSearchBarProps {
  onBlurProp?: () => void
}

const AdminSearchBar = ({ onBlurProp }: AdminSearchBarProps) => {
  const userStore = useContext(UserStoreContext)
  const { user } = userStore
  const rootStore = useRootStore()
  const { history } = useReactRouter()
  const searchRef = useRef('')
  const { loadOptions } = useAutoCompleteAdminSearch({
    query: GET_ADMIN_SEARCH_QUERY,
    minInputLength: 1
  })
  const { isCoachSuperAdmin } = useGetCurrentUserType()

  const [getStudentById] = useLazyQuery(GET_STUDENT_BY_ID)
  const [getCoachById] = useLazyQuery(GET_COACH_BY_ID)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isOpenCoach, setIsOpenCoach] = useState<boolean>(false)
  const handleClickCreate = () => {
    setIsOpen(true)
  }
  const handleClickCreateCoach = () => {
    setIsOpenCoach(true)
  }

  const routeToStudent = (searchedUser: Types.Student) => {
    userStore.detach(user)
    userStore.loadUser(searchedUser)
    history.push('/user-details')
    searchRef.current = ''
  }

  const routeToCoach = (searchedUser: Types.Coach) => {
    rootStore.loadCoach(searchedUser)
    history.push(`/admin/coach-details`)
    searchRef.current = ''
  }
  const handleStudentClick = async (studentId: string) => {
    const studentResult = await getStudentById({
      variables: { student_id: studentId }
    })
    routeToStudent(studentResult.data.getStudentById)
  }

  const handleCoachClick = async (coachId: string) => {
    const coachResult = await getCoachById({
      variables: { coach_id: Number(coachId) }
    })
    routeToCoach(coachResult.data.getCoachById)
  }

  return (
    <>
      <Form.Group className="mb-0">
        <Form.InputGroup>
          <AsyncSelect
            autoFocus={true}
            onBlur={onBlurProp}
            backspaceRemovesValue={true}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
              LoadingIndicator: () => null,
              Option: (props) => {
                return (
                  <>
                    {props.data.type === 'none' ? (
                      <components.Option {...props}>
                        <Box
                          display="flex"
                          alignItems="start"
                          justifyContent="space-between"
                        >
                          {isCoachSuperAdmin && (
                            <Button
                              icon="user-plus"
                              color="secondary"
                              size="sm"
                              className="search-container-dropdown-item m-1"
                              onClick={() => {
                                searchRef.current = props.data.label
                                handleClickCreate()
                              }}
                            >
                              USER
                            </Button>
                          )}
                          {isCoachSuperAdmin && (
                            <Button
                              icon="user-plus"
                              color="secondary"
                              size="sm"
                              className="search-container-dropdown-item m-1"
                              onClick={() => {
                                searchRef.current = props.data.label
                                handleClickCreateCoach()
                              }}
                            >
                              COACH
                            </Button>
                          )}
                        </Box>
                      </components.Option>
                    ) : (
                      <components.Option {...props}>
                        <Box
                          display="flex"
                          alignItems="start"
                          justifyContent="space-between"
                        >
                          <span>
                            {' '}
                            <>
                              {props.data.type === 'location' ? (
                                <i className="fe fe-map-pin mt-1 mr-1 text-danger"></i>
                              ) : props.data.type === 'team' ? (
                                <i className="fe fe-users mt-1 mr-1 text-primary"></i>
                              ) : props.data.type === 'event' ? (
                                <i className="fe fe-calendar mt-1 mr-1 text-orange"></i>
                              ) : props.data.type === 'student' ? (
                                <i
                                  className={`fe ${
                                    props.data.registrationId
                                      ? 'fe-user text-success'
                                      : 'fe-user-x text-muted'
                                  } mt-1 mr-1`}
                                ></i>
                              ) : props.data.type === 'coach' ? (
                                <i
                                  className={`fe ${
                                    props.data.registrationId
                                      ? 'fe-user text-primary'
                                      : 'fe-user-x text-gray-dark'
                                  } mt-1 mr-1`}
                                ></i>
                              ) : (
                                props.data.type
                              )}
                            </>
                            {props.data.label}
                          </span>
                          <Tag className="small ml-1">
                            {props.data.type === 'coach' && '0'}
                            {props.data.value}
                          </Tag>
                        </Box>
                      </components.Option>
                    )}
                  </>
                )
              }
            }}
            escapeClearsValue={true}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.id}
            isClearable={true}
            loadOptions={loadOptions}
            name="search"
            onChange={(e) => {
              if (!e) {
                return
              }
              if (e.type === 'team') {
                window.location.href = `/team-details/${e.value}`
                return
              }
              if (e.type === 'location') {
                history.push(`/locations/${e.value}`)
                return
              }
              if (e.type === 'event') {
                history.push(`/calendar/${e.value}`)
                return
              }
              if (e.type === 'student') {
                handleStudentClick(e.value)
              }
              if (e.type === 'coach') {
                handleCoachClick(e.value)
              }
            }}
            placeholder="Search SKYCRU"
            styles={{ ...autoCompleteStyles }}
          />
        </Form.InputGroup>
      </Form.Group>
      <StudentCreateFromSearch
        name={searchRef.current}
        isModalOpen={isOpen}
        toggleModal={setIsOpen}
      />
      <AddCoachFromSearch
        name={searchRef.current}
        isModalOpen={isOpenCoach}
        toggleModal={setIsOpenCoach}
      />
    </>
  )
}

export default AdminSearchBar
