import React from 'react'
import moment from 'moment'
import { Avatar, Card, Dropdown, Icon, Text } from 'tabler-react'
import ReactHtmlParser from 'react-html-parser'
import * as DOMPurify from 'dompurify'
import { EUserFlags } from '../../../user-flag/constants'
import { useRootStore } from '../../../../hooks'
import useGetCurrentUserType from '../../../common/hooks/useGetCurrentUserType'
import { useMutation } from '@apollo/client'
import { ADD_USER_FLAG } from '../../../../graphql/ADD_USER_FLAG'
import { toast } from 'react-toastify'
import { DELETE_USER_FLAG } from '../../../../graphql/DELETE_USER_FLAG'
import { DELETE_USER_COMMENT } from '../../../../graphql/DELETE_USER_COMMENT'

const CommentItem = ({ comment }) => {
  const { currentUser } = useRootStore()

  const isCommentAuthor =
    currentUser.id === comment.student_id ||
    currentUser.student_id === comment.student_id
  const { isStudent, isAdmin, isCoachAdmin, isCoachSuperAdmin } =
    useGetCurrentUserType()
  const hasStudentId = isStudent || currentUser.student_id
  const isAuthorPost =
    currentUser.id === comment.coach_id ||
    currentUser.student_id === comment.student_id ||
    currentUser.id === comment.student_id

  const [addFlag] = useMutation(ADD_USER_FLAG, {
    onCompleted: () => toast.success('Reaction added!')
  })
  const [removeFlag] = useMutation(DELETE_USER_FLAG, {
    onCompleted: () => toast.success('Reaction removed!')
  })

  const handleLike = async (flagId) => {
    if (isCommentAuthor) return
    await addFlag({
      variables: {
        userFlag: {
          student_id: isStudent ? currentUser.id : currentUser.student_id,
          user_comment_id: comment.id,
          flag_type_id: flagId
        }
      },
      refetchQueries: ['GET_USER_POSTS']
    })
  }

  const handleRemoveFlag = async (id) => {
    removeFlag({
      variables: {
        id
      },
      refetchQueries: ['GET_USER_POSTS']
    })
  }

  const [deleteComment] = useMutation(DELETE_USER_COMMENT, {
    onCompleted: (result) => {
      if (result.deleteComment) {
        toast.success('Comment deleted!')
      }
    }
  })

  const deletePost = () => {
    if (window.confirm('Delete comment?')) {
      deleteComment({
        variables: {
          id: comment.id
        },
        refetchQueries: ['GET_USER_POSTS']
      })
    }
  }
  return (
    <>
      <Card className="p-0 mb-0">
        <Card.Body className="p-3">
          <div className="mb-0 ml-2">
            <Dropdown
              className="text-muted pl-0 pr-0 float-right"
              toggle={false}
              icon="more-horizontal"
              isNavLink={true}
              position="bottom-end"
              arrow={true}
              arrowPosition="right"
              items={
                <>
                  {/*{(isAdmin ||*/}
                  {/*  isAuthorPost ||*/}
                  {/*  isCoachSuperAdmin ||*/}
                  {/*  [*/}
                  {/*    comment.coach_id,*/}
                  {/*    comment.student_id*/}
                  {/*  ].includes(currentUser.id)) && (*/}
                  {/*  <Dropdown.Item*/}
                  {/*    className="cursor-pointer"*/}
                  {/*    onClick={() => handleEditLink(true)}*/}
                  {/*  >*/}
                  {/*    <Icon name="edit" /> Edit*/}
                  {/*  </Dropdown.Item>*/}
                  {/*)}*/}
                  {(isAdmin ||
                    isAuthorPost ||
                    isCoachAdmin ||
                    isCoachSuperAdmin) && (
                    <Dropdown.Item
                      className="cursor-pointer"
                      onClick={() => deletePost()}
                    >
                      <Icon name="trash" /> Delete
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    className="text-muted cursor-pointer"
                    onClick={() => {
                      if (!comment.post_reported) {
                        handleLike(EUserFlags.report)
                      } else {
                        handleRemoveFlag(comment.report_id)
                      }
                    }}
                  >
                    <Icon name="alert-triangle" /> Report Post
                  </Dropdown.Item>
                </>
              }
            />
            <Avatar
              size="md"
              className="mr-3 float-left mb-2 d-block"
              imageURL={
                (comment.student_avatar &&
                  `/api/s3/uploads/${comment.student_avatar}`) ||
                '//www.gravatar.com/avatar?d=mp'
              }
            />
          </div>

          <Text>
            <strong>
              {comment.student_first_name} {comment.student_last_name}
            </strong>
            <br />
            <Text.Small className="text-muted">
              {moment(comment.created_on).isValid() &&
                moment(comment.created_on).format('MMM DD, YYYY - h:mm')}
              <i>{(comment.updated_on !== comment.created_on) && ' '}</i>
            </Text.Small>
          </Text>
          <Text className="ws-prewrap textEditor-display d-block ml-2 mt-2">
            {ReactHtmlParser(DOMPurify.sanitize(comment.content))}
          </Text>
        </Card.Body>
      </Card>
      {/* hasStudentId && (
        <List inline className="float-right list-inline-dots mb-0 ml-2">
          <List.Item inline className="text-muted m-1 pb-3 cursor-pointer">
            <span
              onClick={() => {
                if (!comment.comment_liked) {
                  handleLike(EUserFlags.like)
                } else {
                  handleRemoveFlag(comment.liked_id)
                }
              }}
              className={`${
                comment.comment_liked ? 'text-primary' : 'text-muted'
              }`}
            >
              <i className={`fe fe-thumbs-up mr-2`} />
              Like
            </span>
          </List.Item>
          <List.Item inline className="text-muted m-1 pb-3 cursor-pointer">
            <span
              onClick={() => {
                if (!comment.comment_loved) {
                  handleLike(EUserFlags.love)
                } else {
                  handleRemoveFlag(comment.loved_id)
                }
              }}
              className={`${
                comment.comment_loved ? 'text-primary' : 'text-muted'
              }`}
            >
              <i className={`fe fe-heart mr-2`} />
              Love
            </span>
          </List.Item>
        </List>
      ) */}
      <Text.Small className="float-left text-muted m-1 pb-3 ml-0">
        {/*
          <i className="fe fe-calendar mr-6 text-muted" />
          {moment(userPost.created_on).format('MMM DD, YYYY - h:mm')}
          {userPost.updated_on && ' edited'}
*/}
        <span
          onClick={() => {
            if (!comment.comment_liked) {
              handleLike(EUserFlags.like)
            } else {
              handleRemoveFlag(comment.liked_id)
            }
          }}
          className={
            'cursor-pointer ml-2 mr-3 ' +
            `${comment?.commentMeta.likes ? 'text-primary' : 'text-muted'}`
          }
        >
          <i className="fe fe-thumbs-up mr-1" />
          {comment?.commentMeta.likes || 0}
        </span>
        <span
          onClick={() => {
            if (!comment.comment_loved) {
              handleLike(EUserFlags.love)
            } else {
              handleRemoveFlag(comment.loved_id)
            }
          }}
          className={
            'cursor-pointer mr-3 ' +
            `${comment?.commentMeta.loves ? 'text-danger' : 'text-muted'}`
          }
        >
          <i className="fe fe-heart mr-1" />
          {comment?.commentMeta.loves || 0}
        </span>
      </Text.Small>
    </>
  )
}

export default CommentItem
