import { useQuery } from "@apollo/client";
import StaffCoachListItem from "components/Staff/StaffCoachListItem";
import { TEAM_DETAILS_QUERY } from "graphql/TEAM_DETAILS_QUERY";
import { TEAM_DETAILS_QUERY as TEAM_DETAILS_QUERY_TYPE } from "graphql/types/TEAM_DETAILS_QUERY";
import { useRootStore } from "hooks";
import { observer } from "mobx-react";
import { Team } from "models/TeamStore";
import AddCoachModal from "modules/coach/components/modals/AddCoachModal";
import useGetCurrentUserType, {
  COACH_SUPERADMIN_IDS,
} from "modules/common/hooks/useGetCurrentUserType";
import { FC, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { Alert, Button, Card, Dimmer, Grid, Icon, Table } from "tabler-react";

const AddCoachButton: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <Card.Options>
      <Button
        icon="user-plus"
        color="white"
        className="text-primary"
        onClick={toggleModal}
      >
        TEAM ACCOUNT
      </Button>
      <AddCoachModal isModalOpen={isModalOpen} toggleModal={toggleModal} />
    </Card.Options>
  );
};

const StaffCoachList = () => {
  const { currentCoachTeam } = useRootStore();
  const { isAdmin, isCoachAdmin, isCoachSuperAdmin } = useGetCurrentUserType();

  const { search } = useLocation();

  const teamId = useMemo(() => {
    return (
      currentCoachTeam?.id ?? Number(new URLSearchParams(search).get("team_id"))
    );
  }, [currentCoachTeam, search]);

  const { loading, error, data } = useQuery<TEAM_DETAILS_QUERY_TYPE>(
    TEAM_DETAILS_QUERY,
    {
      variables: {
        team_id: teamId,
      },
    },
  );

  const coachesRow = useMemo(
    () =>
      data?.geCoachesByTeamId.filter((coach) => {
        if (COACH_SUPERADMIN_IDS.includes(coach.id)) {
          return false;
        }

        return coach.is_active && !coach.is_disabled_on;
      }),
    [data?.geCoachesByTeamId],
  );

  if (error) {
    return <p>Error: {`Error: ${error.message}`}</p>;
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <Icon name="users" className="mr-2 ml-0 text-blue" />
          TEAM Accounts
        </Card.Title>
        {(isAdmin || isCoachAdmin || isCoachSuperAdmin) && <AddCoachButton />}
      </Card.Header>
      <Card.Body>
        {!coachesRow?.length && (
          <Alert type="info text-center">
            <strong>No Data</strong>
          </Alert>
        )}

        {coachesRow?.length > 0 && (
          <Grid.Row>
            <Grid.Col>
              <Dimmer active={loading} loader={loading}>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader></Table.ColHeader>
                      <Table.ColHeader>Email</Table.ColHeader>
                      <Table.ColHeader>Phone</Table.ColHeader>
                      {(isAdmin || isCoachAdmin || isCoachSuperAdmin) && (
                        <>
                          <Table.ColHeader>Role(s)</Table.ColHeader>
                          <Table.ColHeader></Table.ColHeader>
                        </>
                      )}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {coachesRow?.map((coach) => (
                      <Table.Row key={coach.id}>
                        <StaffCoachListItem coach={coach} teamId={teamId} />
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Dimmer>
            </Grid.Col>
          </Grid.Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default observer(StaffCoachList);
