import { ApolloError, useMutation, useQuery } from '@apollo/client'
import { RootStoreContext } from 'contexts'
import { GET_TEAM_BY_SLUG } from 'graphql/GET_TEAM_BY_SLUG'
import { ONBOARD_TEAM } from 'graphql/ONBOARD_TEAM'
import { GET_TEAM_BY_SLUG as GET_TEAM_BY_SLUG_TYPE } from 'graphql/types/GET_TEAM_BY_SLUG'
import { FormikStep } from 'modules/common/components/forms/FormikStep'
import FormikStepper from 'modules/common/components/forms/FormikStepper'
import ActivitesStep from 'modules/team/components/forms/steps/ActivitesStep'
import CurrencyStep, {
  currencyValidationSchema
} from 'modules/team/components/forms/steps/CurrencyStep'
import EventsStep from 'modules/team/components/forms/steps/EventsStep'
import LocationStep from 'modules/team/components/forms/steps/LocationStep'
import LogosStep from 'modules/team/components/forms/steps/LogosStep'
import TeamAccountsStep, {
  coachesSchema
} from 'modules/team/components/forms/steps/TeamAccountsStep'
import TeamDetailsStep from 'modules/team/components/forms/steps/TeamDetailsStep'
import { LocationType } from 'modules/team/constants/api'
import { useContext } from 'react'
import { toast } from 'react-toastify'
import { Card } from 'tabler-react'
import { boolean, object, string } from 'yup'
import { EDomainId } from '../../../../components/constants'

export type NewTeamOnboardingFormValues = {
  name: string
  team_bio: string
  team_phone: string
  team_email: string
  team_url?: string
  preference_units: boolean
  slug?: string
  activities: {
    sportTypeId: number
    locationType: LocationType
  }[]
  default_location: {
    value: number
    label: string
  }
  default_currency: string
  is_feature_payments: boolean
  payment_fee_customer?: number
  is_feature_camps: boolean
  is_feature_registrations: boolean
  team_logo_wide_image?: string
  team_logo_square_image?: string
  team_logo_header?: string
  coaches: {
    value: number
    label: string
  }[]
}

type NewTeamOnboardingFormProps = {
  toggleModal: () => void
}

const NewTeamOnboardingForm = ({ toggleModal }: NewTeamOnboardingFormProps) => {
  const { domainData } = useContext(RootStoreContext)
  const [onboardTeam] = useMutation(ONBOARD_TEAM, {
    onError: (error: ApolloError) =>
      toast.error(error?.graphQLErrors[0]?.message),
    onCompleted: () => {
      toast.success('Team Added.')
    }
  })

  const { refetch: slugUniqueness } = useQuery<GET_TEAM_BY_SLUG_TYPE>(
    GET_TEAM_BY_SLUG,
    {
      skip: true
    }
  )

  const teamDetailsSchema = object().shape({
    name: string().required('Name is required'),
    team_bio: string(),
    team_email: string().email(),
    team_phone: string(),
    team_url: string().url('Invalid URL').optional(),
    preference_units: boolean()
      .oneOf([false, true])
      .required('This field is required.'),
    slug: string()
      .optional()
      .test('slug', 'Slug already in use', async (value) => {
        if (!value) {
          return true
        }

        // Check if slug already exists
        const { data } = await slugUniqueness({
          teamSlug: value
        })

        return !data?.teamBySlug
      })
  })

  return (
    <Card className="border-0 shadow-none">
      <FormikStepper
        enableReinitialize={true}
        initialValues={{
          name: '',
          team_bio: '',
          team_phone: '',
          team_email: '',
          team_url: '',
          preference_units: false,
          slug: '',
          activities: [],
          default_location: undefined,
          default_currency: undefined,
          is_feature_payments: false,
          payment_fee_customer: undefined,
          is_feature_camps: false,
          is_feature_registrations: false,
          team_logo_wide_image: undefined,
          team_logo_square_image: undefined,
          team_logo_header: undefined,
          coaches: [],
          domain: EDomainId[domainData.key]
        }}
        onSubmit={async (values) => {
          const {
            activities,
            is_feature_payments,
            is_feature_camps,
            is_feature_registrations,
            default_location,
            coaches,
            ...rest
          } = values

          await onboardTeam({
            variables: {
              teamInput: {
                ...rest,
                sport_type_ids: activities.map(
                  (activity) => activity.sportTypeId
                ),
                default_location_id: default_location?.value,
                feature_payments: is_feature_payments ? true : false,
                feature_camps: is_feature_camps ? true : false,
                feature_registrations: is_feature_registrations ? true : false,
                coach_ids: coaches.map((coach) => coach.value)
              }
            },
            refetchQueries: ['GET_TEAMS']
          })

          toggleModal()
        }}
      >
        <FormikStep label="Details" validationSchema={teamDetailsSchema}>
          <TeamDetailsStep />
        </FormikStep>

        <FormikStep label="Activities">
          <ActivitesStep />
        </FormikStep>

        <FormikStep label="Location">
          <LocationStep />
        </FormikStep>

        <FormikStep
          label="Currency"
          validationSchema={currencyValidationSchema}
        >
          <CurrencyStep />
        </FormikStep>

        <FormikStep label="Events">
          <EventsStep />
        </FormikStep>

        <FormikStep label="Logos">
          <LogosStep />
        </FormikStep>

        <FormikStep label="Accounts" validationSchema={coachesSchema}>
          <TeamAccountsStep />
        </FormikStep>
      </FormikStepper>
    </Card>
  )
}

export default NewTeamOnboardingForm
