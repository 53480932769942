import { observer } from 'mobx-react'
import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import { Types } from '../../../../types/graphql'

const SEARCH_LIMIT = 10

interface CampParticipantSearchFieldProps {
  onChange: any
  defaultValue: number
  isDisabled?: boolean
  autoFocus?: boolean
  campId: number
  status?: string
  options?: any
}

const CampParticipantSearchField = ({
  onChange,
  defaultValue,
  isDisabled,
  autoFocus,
  campId,
  status,
  options
}: CampParticipantSearchFieldProps) => {
  const [selectedMember, setSelectedMemeber] = useState(
    Number(defaultValue || 0)
  )

  useEffect(() => {
    setSelectedMemeber(defaultValue || 0)
  }, [defaultValue])

  const studentOptions = useMemo(() => {
    return options
      .filter(
        (student) =>
          student.status === 'Confirmed' ||
          student.status === 'Pending' ||
          student.status === 'Accepted'
      )
      .map((student: Types.RegisteredStudents) => ({
        label: `${student.first_name} ${student.last_name}`,
        value: student.student_id,
        user_registrations_id: student.user_registrations_id
      }))
  }, [options])
  return (
    <Select
      options={studentOptions}
      autoFocus={autoFocus}
      onChange={(e) => {
        onChange(e)
        setSelectedMemeber(e.value)
      }}
      isDisabled={isDisabled}
      value={studentOptions.find((member) => selectedMember === member.value)}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 })
      }}
      menuPortalTarget={document.body}
    />
  )
}

export default observer(CampParticipantSearchField)
