import React, { useState } from 'react'
import { Formik } from 'formik'
import { useMutation } from '@apollo/client'
import { UPDATE_STUDENT_MUTATION } from '../../../../graphql/UPDATE_STUDENT_MUTATION'
import { UPDATE_COACH_BY_ID } from '../../../../graphql/UPDATE_COACH_BY_ID'
import { EUserTypes } from '../../../../components/User/userTypes'
import { toast } from 'react-toastify'
import FormField from '../../../../components/FormField'
import { useRootStore } from '../../../../hooks'
import Modal from '../../../../components/Modal'
import { Button, Grid } from 'tabler-react'
import { capitalizeName } from '../../../../utils/stringFormat'
import * as Yup from 'yup'

interface UserIdentityModalProps {
  user: {
    id: number
    first_name: string
    last_name: string
    email: string
  }
  type: string
}

const validationSchema = Yup.object({
  first_name: Yup.string().required('First Name is Required'),
  last_name: Yup.string().required('Last Name is Required'),
  email: Yup.string().required('Email is Required'),
  password: Yup.string()
    .required('Password is Required')
    .min(6, 'Password must be at least 6 characters'),
  confirm_password: Yup.string().when('password', {
    is: (password) => password?.length > 0, // If password is filled
    then: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Please Confirm Password'),
    otherwise: Yup.string().notRequired() // If no password, confirm password is not required
  })
})

const UserIdentityModal = ({ user, type }: UserIdentityModalProps) => {
  const rootstore = useRootStore()
  const [isOpen, setIsOpen] = useState(rootstore.hasMissingData === 1)
  const [updateStudent] = useMutation(UPDATE_STUDENT_MUTATION, {
    onCompleted: () => toast.success('Account data updated!')
  })
  const [updateCoach] = useMutation(UPDATE_COACH_BY_ID, {
    onCompleted: () => toast.success('Account data updated!')
  })

  const handleClose = () => {
    rootstore.setHasMissingData(2)
    setIsOpen(false)
  }
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{ ...user, password: '', confirm_password: '' }}
      enableReinitialize={true}
      onSubmit={async (values) => {
        if (type === EUserTypes.student) {
          await updateStudent({
            variables: {
              student: {
                id: values.id,
                email: values.email,
                first_name: values.first_name,
                last_name: values.last_name,
                password: values.password || undefined
              }
            },
            onCompleted: () => {
              rootstore.me()
            }
          })
        } else {
          await updateCoach({
            variables: {
              coach: {
                id: values.id,
                email: values.email,
                first_name: values.first_name,
                last_name: values.last_name,
                password: values.password || undefined
              }
            }
          })
        }
        handleClose()
      }}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => {
        return (
          <Modal
            title={'Update Login'}
            open={isOpen}
            content={
              <form autoComplete="off">
                <Grid.Row>
                  {!user.first_name && (
                    <Grid.Col>
                      <FormField
                        label={'First Name'}
                        name={'first_name'}
                        onChange={(e) => {
                          setFieldValue(
                            'first_name',
                            capitalizeName(e.target.value)
                          )
                        }}
                        value={values.first_name}
                      />
                    </Grid.Col>
                  )}
                  {!user.last_name && (
                    <Grid.Col>
                      <FormField
                        label={'Last Name'}
                        name={'last_name'}
                        onChange={(e) => {
                          setFieldValue(
                            'last_name',
                            capitalizeName(e.target.value)
                          )
                        }}
                        value={values.last_name}
                      />
                    </Grid.Col>
                  )}
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col>
                    <FormField
                      label="Email"
                      name="email"
                      onChange={(e) => {
                        setFieldValue('email', e.target.value.toLowerCase());
                      }}
                      value={values.email}
                    />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col>
                    <FormField
                      label={'Password'}
                      type={'password'}
                      name="password"
                      onChange={(e) =>
                        setFieldValue('password', e.target.value)
                      }
                      value={values.password}
                    />
                  </Grid.Col>
                  <Grid.Col>
                    <FormField
                      label={'Confirm Password'}
                      type={'password'}
                      name="confirm_password"
                      onChange={(e) =>
                        setFieldValue('confirm_password', e.target.value)
                      }
                      value={values.confirm_password}
                    />
                  </Grid.Col>
                </Grid.Row>
              </form>
            }
            actions={
              <>
                <Button size={'sm'} pill color={'white'} onClick={handleClose}>
                  NOT NOW
                </Button>
                <Button
                  size={'sm'}
                  pill
                  color={'primary'}
                  onClick={handleSubmit}
                >
                  SUBMIT
                </Button>
              </>
            }
            onClose={handleClose}
          />
        )
      }}
    </Formik>
  )
}

export default UserIdentityModal
