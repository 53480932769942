import { useLazyQuery } from '@apollo/client'
import Downshift from 'downshift'
import debounce from 'lodash.debounce'
import { observer } from 'mobx-react'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Icon, Tag } from 'tabler-react'
import { GET_STUDENTS_BY_FILTER_QUERY } from '../../../graphql/GET_STUDENTS_BY_FILTER_QUERY'
import { EOrderOptions, IArgsOptions } from '../../../hooks/useAutocomplete'
import { useRootStore } from '../../../hooks/useRootStore'
import { Types } from '../../../types/graphql'
import { EUserFilterOptions } from '../../enum'
import AddStudentFromSearchForm from './AddStudentFromSearchForm'

const SEARCH_LIMIT = 10

interface StudentSearchFieldProps {
  onChange: any
  defaultValue?: string
  isDisabled?: boolean
  autoFocus?: boolean
  exact_match?: boolean
  addOnEmptyResult?: boolean
  ignore_team?: boolean
}

const StudentSearchField = ({
  onChange,
  defaultValue,
  isDisabled,
  autoFocus,
  exact_match,
  addOnEmptyResult,
  ignore_team
}: StudentSearchFieldProps) => {
  const rootStore = useRootStore()
  const { currentCoachTeam } = rootStore

  const [filteredStudents, setFilteredStudents] = useState([])
  const searchRef = useRef(defaultValue || '')
  const [isOpenForm, setIsOpenForm] = React.useState(false)

  let argsOptions: IArgsOptions = {
    order: EOrderOptions.alphabet,
    filter: { filter_by: EUserFilterOptions.all }
  }

  if (currentCoachTeam?.id) {
    argsOptions = {
      ...argsOptions,
      filter: {
        team_id: currentCoachTeam?.id.toString(),
        filter_by: EUserFilterOptions.enabled
      }
    }
  }

  const setValue = (searchedUser: Types.Student) => {
    searchRef.current = `${searchedUser.first_name} ${searchedUser.last_name}`
  }

  const changeEvent = (searchedUser: Types.Student) => {
    if (searchedUser) {
      onChange(searchedUser)
      setValue(searchedUser)
      if (isOpenForm) setIsOpenForm(false)
    }
    if (!searchedUser && addOnEmptyResult) setIsOpenForm(!isOpenForm)
  }

  const [students, { loading, error, data }] = useLazyQuery(
    GET_STUDENTS_BY_FILTER_QUERY
  )

  // Set filtered students everytime data changes
  useEffect(() => {
    if (!loading && !error && data) {
      setFilteredStudents(data[Object.keys(data)[0]])
    }
  }, [data])

  const handleChange = debounce(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length > 0)
        students({
          variables: {
            ...argsOptions,
            filter: {
              ...argsOptions.filter,
              limit: SEARCH_LIMIT,
              search: e.target.value,
              exact_match: exact_match,
              ignore_team: ignore_team
            }
          }
        })
    },
    360
  )

  return (
    <>
      <div className="search-container w-150">
        {!isOpenForm && (
          <Downshift onChange={changeEvent}>
            {({
              getInputProps,
              getItemProps,
              isOpen,
              inputValue,
              highlightedIndex
            }) => (
              <div>
                <input
                  {...getInputProps({
                    className: 'form-control cursor-pointer',
                    id: 'search',
                    placeholder: 'Search by name, email, or phone...',
                    type: 'search',
                    value: searchRef.current,
                    disabled: isDisabled,
                    autoFocus: true,
                    //  autoFocus: autoFocus || false,
                    onChange: (e) => {
                      searchRef.current = e.target.value
                      e.persist()
                      handleChange(e)
                    }
                  })}
                />
                {isOpen && (
                  <div className="search-container-dropdown">
                    {filteredStudents.map((item, idx) => (
                      <div
                        {...getItemProps({ item })}
                        className={`px-2 my-1  ${
                          idx === highlightedIndex &&
                          'search-container-dropdown-item-highlighted'
                        }`}
                        key={item.id}
                      >
                        {!item?.registration_id ? (
                          <Icon
                            name="user-x"
                            className="mr-2 ml-0 text-secondary"
                          />
                        ) : (
                          <Icon
                            name="user"
                            className="mr-2 ml-0 text-success"
                          />
                        )}
                        <span>{`${item.first_name} ${item.last_name}`}</span>
                        <Tag className="small ml-1 float-right">{item.id}</Tag>
                      </div>
                    ))}
                    {!filteredStudents.length && !loading && (
                      <>
                        <div className="search-container-dropdown-item text-muted">
                          No results for {inputValue}.
                        </div>
                        <div className="text-muted font-italic search-container-dropdown-item">
                          <small>
                            Search by first name, last name, exact email or
                            phone number
                          </small>
                        </div>
                        {addOnEmptyResult && (
                          <div className="search-container-dropdown-item">
                            <Button
                              outline
                              icon="user-plus"
                              color="secondary"
                              size="sm"
                              onClick={() => setIsOpenForm(true)}
                            >
                              ADD USER
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
          </Downshift>
        )}
        {isOpenForm && (
          <AddStudentFromSearchForm
            first_name={searchRef.current}
            changeEvent={changeEvent}
            toggle={() => setIsOpenForm(false)}
          />
        )}
      </div>
    </>
  )
}

export default observer(StudentSearchField);
