import { useMutation } from '@apollo/client'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import CurrencyInputField from 'components/CurrencyInputField'
import FormField from 'components/FormField'
import Modal from 'components/Modal'
import { EPaymentOptions } from 'components/Stripe/account-balance/StripeAccountBalanceModal'
import { EUserTypes } from 'components/User/userTypes'
import { Formik, FormikValues } from 'formik'
import { ADD_USER_EVENT } from 'graphql/ADD_USER_EVENT'
import { CHARGE_STRIPE_V2 } from 'graphql/CHARGE_STRIPE_V2'
import { GET_PAYMENTS } from 'graphql/GET_PAYMENTS'
import { INSERT_PAYMENT } from 'graphql/INSERT_PAYMENT'
import { useRootStore } from 'hooks'
import { manualPaymentOptions } from 'hooks/manualPayments'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'
import {
  calculateFees,
  calculateTotalWithFees,
  manualFormSchema,
  stripeFormSchema
} from 'modules/invoice/components/modals/InvoicePaymentModal'
import { EManualPaymentOpts } from 'modules/payment/constants/manualPaymentOptions'
import { EPaymentStatus } from 'modules/payment/constants/paymentStatus'
import React, { FC } from 'react'
import { toast } from 'react-toastify'
import { Alert, Button, Card, Form, Grid, Text } from 'tabler-react'
import { formatMoney, getCurrencySymbol } from 'utils/numberFormat'
import { Types } from '../../../../../types/graphql'

interface GroupPaymentModalProps {
  amount: number
  currency: string
  isModalOpen: boolean
  name: string
  paymentFee: number
  group: Types.Group
  toggleModal: () => void
  paymentDescription: string
  paymentEmail?: string
  paypal?: boolean
  venmo?: boolean
}

const GroupPaymentModal: FC<GroupPaymentModalProps> = ({
  amount,
  currency,
  isModalOpen,
  name,
  paymentFee,
  group,
  toggleModal,
  paymentDescription,
  paymentEmail,
  paypal,
  venmo
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const { currentUser } = useRootStore()
  const team_id = Number(group?.team_id)
  const { isStudent } = useGetCurrentUserType()

  const canMakeManualPayment = currentUser.type !== EUserTypes.student
  const { group_code, id: group_id } = { ...group }

  const [form, setForm] = React.useState('stripe')

  const [userEvent] = useMutation(ADD_USER_EVENT)
  const [chargeStripeV2] = useMutation(CHARGE_STRIPE_V2, {
    onCompleted: () => {
      // userEvent({
      //   variables: {
      //     userEvent: {
      //       user_event_type_id: EUserEventsId.pay_complete,
      //       group_id,
      //       status: 'Paid',
      //       team_id
      //     }
      //   },
      //   refetchQueries: []
      // })
      toast.success('Payment saved.')
    }
  })
  const [insertPayment] = useMutation(INSERT_PAYMENT, {
    onCompleted: () => {
      // userEvent({
      //   variables: {
      //     userEvent: {
      //       user_event_type_id: EUserEventsId.pay_complete,
      //       group_id,
      //       status: 'Paid',
      //       team_id
      //     }
      //   },
      //   refetchQueries: []
      // })
      toast.success('Payment saved.')
    }
  })

  const stripeTokenHandler = async ({
    token,
    email,
    total,
    amount,
    fees,
    card_customer_name
  }) => {
    const description = `${paymentDescription} ${name} ${group_code} `

    try {
      await chargeStripeV2({
        refetchQueries: [
          {
            query: GET_PAYMENTS,
            variables: {
              params: {
                group_id: Number(group_id)
              }
            }
          },
          'GET_GROUPS',
          'GET_REGISTRATION_GROUPS'
        ],
        variables: {
          chargeParams: {
            source: token.id,
            total,
            amount,
            fees,
            description,
            currency,
            group_id,
            team_id,
            student_id: isStudent ? currentUser.id : undefined,
            card_customer_name
          }
        }
      })
      toggleModal()
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  const renderStripeForm = ({
    errors,
    values,
    handleSubmit,
    isSubmitting,
    setFieldValue
  }) => (
    <form onSubmit={handleSubmit} className="stripe-form">
      <fieldset disabled={isSubmitting}>
        <Grid.Row>
          <Grid.Col xs={12} sm={12} md={6} lg={6}>
            <CurrencyInputField
              name="total"
              value={values.total.toLocaleString()}
              setFieldValue={setFieldValue}
              prefix={getCurrencySymbol(currency)}
              customInput={undefined}
              className="text-xxl text-center border-bottom text-muted form-control"
            />
            <span className="field-error text-danger">
              {errors.total && errors.total}
            </span>
            <Grid.Col width={12} className="pl-0 pr-0">
              <Text.Small className="float-right text-muted">
                Fees: {formatMoney(Number(values.total) * paymentFee, currency)}{' '}
                <span
                  className="cursor-help"
                  title={`${(paymentFee * 100).toFixed(
                    2
                  )}% includes all processing fees, taxes, etc.`}
                >
                  <i className="fe fe-help-circle ml-1" />
                </span>
              </Text.Small>
              <Text.Small className="text-muted">{currency}</Text.Small>
            </Grid.Col>
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={6} lg={6}>
            <Grid.Row className="mb-4 mt-3">
              <Grid.Col className="text-right">{paymentDescription}</Grid.Col>
            </Grid.Row>
            <Grid.Row className="mb-4">
              <Grid.Col className="text-right">
                <Text>
                  <span className="text-muted">Group Code:</span> {group_code}
                </Text>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col width={12}>
            <FormField
              appendleft={<Button icon={'mail'} color={'secondary'} disabled />}
              name="email"
              label="Email"
              type="text"
              value={values.email}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col width={12}>
            <FormField
              appendleft={<Button icon={'user'} color={'secondary'} disabled />}
              name="name"
              label="Name on Card"
              type="text"
            />
          </Grid.Col>
        </Grid.Row>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4'
                },
                lineHeight: '1.5rem'
              },
              invalid: {
                color: '#9e2146'
              }
            },
            classes: {
              base: 'form-group form-control'
            }
          }}
        />
        <Button
          block
          color="primary"
          type="submit"
          disabled={!stripe || isSubmitting}
        >
          {isSubmitting
            ? 'Processing...'
            : `PAY ${formatMoney(
                values.total + values.total * paymentFee,
                currency
              )}`}
        </Button>
        <Text.Small className="text-muted">
          Powered by{' '}
          <a
            href="https://stripe.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Stripe
          </a>
          .{' View Stripe '}
          <a
            href="https://stripe.com/legal/end-users"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms
          </a>
          {' and '}
          <a
            href="https://stripe.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy
          </a>
          .
        </Text.Small>
      </fieldset>
    </form>
  )

  const renderManualForm = ({
    errors,
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue
  }: FormikValues) => (
    <form onSubmit={handleSubmit}>
      <fieldset disabled={isSubmitting}>
        <Grid.Row>
          <Grid.Col xs={12} sm={12} md={6} lg={6}>
            <CurrencyInputField
              name="amount"
              value={values.amount}
              setFieldValue={setFieldValue}
              prefix={getCurrencySymbol(currency)}
              customInput={undefined}
              className="text-xxl text-center border-bottom text-muted form-control"
            />
            <span className="field-error text-danger">
              {errors.amount && errors.amount}
            </span>
            <Grid.Col width={12} className="pl-0 pr-0 mb-3">
              <Text.Small className="text-muted">{currency}</Text.Small>
            </Grid.Col>
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={6} lg={6}>
            <Grid.Row className="mb-4 mt-3">
              <Grid.Col className="text-right">{paymentDescription}</Grid.Col>
            </Grid.Row>
            <Grid.Row className="mb-4">
              <Grid.Col className="text-right">
                <Text>
                  <span className="text-muted">Group Code:</span> {group_code}
                </Text>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col>
            <Form.Group label="Email">
              <FormField
                appendleft={
                  <Button icon={'mail'} color={'secondary'} disabled />
                }
                name="send_to"
                placeholder="Email"
                value={values.send_to}
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group>
              <Form.Select
                name="manual_payment"
                onChange={handleChange}
                className="text-uppercase"
              >
                {manualPaymentOptions(currency).map((paymentType) => (
                  <option value={paymentType.value} key={paymentType.value}>
                    {paymentType.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={6} lg={6}>
            <Button block color="primary" className="" type="submit">
              Log Payment of {formatMoney(values.amount, currency)}
            </Button>
          </Grid.Col>
        </Grid.Row>
      </fieldset>
    </form>
  )

  const StripeFormik = () => {
    return (
      <Formik
        enableReinitialize={true}
        validationSchema={stripeFormSchema({
          name,
          email: '',
          total: amount
        })}
        initialValues={{
          name: '',
          email: paymentEmail ?? '',
          total: amount
        }}
        onSubmit={async (values, { resetForm, setSubmitting }) => {
          const { name, email, total } = values

          if (!stripe || !elements) {
            return
          }

          const card = elements.getElement(CardElement)
          const result = await stripe.createToken(card, {
            name,
            currency
          })

          if (result.error) {
            toast.error(result.error.message)
            return
          }

          await stripeTokenHandler({
            token: result.token,
            email,
            total,
            amount: calculateTotalWithFees(total, paymentFee),
            fees: calculateFees(total, paymentFee),
            card_customer_name: name
          })
          setSubmitting(false)
          resetForm()
          toggleModal()
        }}
      >
        {(formikData) => renderStripeForm(formikData)}
      </Formik>
    )
  }
  const ManualFormik = () => {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          amount: amount,
          manual_payment: EManualPaymentOpts.cash,
          group_id,
          description: `${paymentDescription} ${name} ${group_code} `,
          send_to: paymentEmail ?? ''
        }}
        validationSchema={manualFormSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await insertPayment({
            variables: {
              paymentParams: {
                ...values,
                currency,
                processed_by: 'Manual Payment',
                status:
                  values.manual_payment === EManualPaymentOpts.comp
                    ? EPaymentStatus.comp
                    : EPaymentStatus.paid,
                created_by: currentUser.id,
                team_id
              }
            },
            refetchQueries: [
              {
                query: GET_PAYMENTS,
                variables: {
                  params: {
                    group_id: Number(group_id)
                  }
                }
              },
              'GET_GROUPS',
              'GET_REGISTRATION_GROUPS',
              'GET_CAMP_GROUP_PAYMENT_SUMMARY'
            ]
          })
          setSubmitting(false)
          resetForm()
          toggleModal()
        }}
      >
        {(formikData) => renderManualForm(formikData)}
      </Formik>
    )
  }

  const venmoLink = () => {
    window.open(
      `https://venmo.com/${venmo}?txn=pay&note=${group.group_code}&amount=${
        group.paymentDetails.outstanding / 100
      }`,
      '_blank'
    )
  }
  const paypalLink = () => {
    window.open(
      `https://www.paypal.me/${paypal}/${
        group.paymentDetails.outstanding / 100
      }`,
      '_blank'
    )
  }

  const OtherMethods = () => {
    return (
      <>
        {(venmo || paypal) ? (
          currency === 'USD' && group.paymentDetails.outstanding ? (
            <>
              <Button.List align="right">
                {venmo && (
                  <Button icon="dollar-sign" color="primary" onClick={venmoLink}>
                    VENMO
                  </Button>
                )}
                {paypal && (
                  <Button icon="dollar-sign" color="gray-dark" onClick={paypalLink}>
                    PayPal
                  </Button>
                )}
              </Button.List>
              <Text.Small className="text-muted">Note: These payment options require verification and may take up to 24-48 hours to appear on your account.</Text.Small>
            </>
          ) : null
        ) : (
          <Alert type="info" className="text-center">
            <strong>No additional payment methods</strong>
          </Alert>
        )}
      </>
    )
  }

  return (
    <Modal
      content={
        <>
          <Card.Header className="p-0">
            <Card.Title>
              <Button
                color="primary"
                icon="credit-card"
                outline={form !== EPaymentOptions.stripe}
                onClick={() => setForm(EPaymentOptions.stripe)}
                className={`${
                  form === EPaymentOptions.stripe ? 'active' : ''
                } mr-2 mb-2`}
              >
                CARD
              </Button>
              {canMakeManualPayment && (
              <Button
                color="info"
                icon="list"
                outline={form !== EPaymentOptions.manual}
                onClick={() => setForm(EPaymentOptions.manual)}
                className={`${
                  form === EPaymentOptions.manual ? 'active' : ''
                } mr-2 mb-2`}
              >
                LOG PAYMENT
              </Button>
              )}
              {/* STUDENTS HAVE THE OPTION TO PAY OTHER WAYS */}
                <Button
                  outline={form !== EPaymentOptions.others}
                  icon="dollar-sign"
                  color="secondary"
                  onClick={() => setForm(EPaymentOptions.others)}
                  className={`${
                    form === EPaymentOptions.others ? 'active' : ''
                  } mb-2`}
                >
                  OTHER
                </Button>
            </Card.Title>
          </Card.Header>
          {form === EPaymentOptions.stripe && <StripeFormik />}
          {form === EPaymentOptions.manual && <ManualFormik />}
          {form === EPaymentOptions.others && <OtherMethods />}
        </>
      }
      open={isModalOpen}
      title={name}
      onClose={toggleModal}
    />
  )
}

export default GroupPaymentModal
