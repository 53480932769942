import { useMutation } from '@apollo/client';
import TeamCardDropdown, {
  ETeamImageType
} from 'components/Team/Image/TeamCardDropdown';
import { UPDATE_TEAM_BY_ID } from 'graphql/UPDATE_TEAM_BY_ID';
import { useRootStore } from 'hooks';
import { observer } from 'mobx-react';
import { applySnapshot, getSnapshot } from 'mobx-state-tree';
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType';
import React from 'react';
import { toast } from 'react-toastify';
import { Card, Dropdown, GalleryCard, Text } from 'tabler-react';

import productImagePlaceholder from '../../../assets/images/product-image-placeholder.png';

interface ITeamImagesProps {
  team: any;
  teamClone: any;
}

const TeamImages = ({ team, teamClone }: ITeamImagesProps) => {
  const rootStore = useRootStore();
  const { isAdmin } = useGetCurrentUserType();
  const [updateTeamById] = useMutation(UPDATE_TEAM_BY_ID);

  const onError = (error) => {
    toast.error(error);
    rootStore.setLoading(false);
  };

  const onFinished = (response: { fileKey: string }, type: ETeamImageType) => {
    updateTeamImages(response.fileKey, type);

    rootStore.setLoading(false);
  };

  const updateTeamImages = async (
    fileKey: string | null,
    type: ETeamImageType
  ) => {
    teamClone.set(type, fileKey);

    await updateTeamById({
      refetchQueries: ['GET_TEAMS'],
      variables: {
        team: {
          id: parseInt(teamClone.id),
          team_favicon:
            type === ETeamImageType.team_favicon
              ? fileKey
              : teamClone.team_favicon,
          team_logo_header:
            type === ETeamImageType.team_logo_header
              ? fileKey
              : teamClone.team_logo_header,
          team_logo_wide_image:
            type === ETeamImageType.team_logo_wide_image
              ? fileKey
              : teamClone.team_logo_wide_image,
          team_logo_square_image:
            type === ETeamImageType.team_logo_square_image
              ? fileKey
              : teamClone.team_logo_square_image
        }
      },
      update(_, { data: { updateTeamById } }) {
        if (updateTeamById) {
          applySnapshot(team, getSnapshot(teamClone));
          toast.success('Team Updated.');
        }
      }
    });
  };

  return (
    <>
     <h5>Wide Logo</h5>
      <Card className="card-profile border-0">
        <Card.Header
          backgroundURL={
            teamClone.team_logo_wide_image
              ? `/api/s3/uploads/${teamClone.team_logo_wide_image}`
              : '//via.placeholder.com/374x144.png?text=+'
          }
        >
          {rootStore.currentUser && (
            <div className="banner-dropdown-container">
              <Dropdown
                className="cursor-pointer banner-dropdown"
                icon="camera"
                items={
                  <TeamCardDropdown
                    imageName={teamClone.team_logo_wide_image}
                    imageType={ETeamImageType.team_logo_wide_image}
                    onErrorHandler={onError}
                    onFinishedHandler={onFinished}
                    callBack={updateTeamImages}
                  />
                }
                toggle={false}
              />
            </div>
          )}
        </Card.Header>
      </Card>
      <h5>Square Logo</h5>
      <Card className="card-profile border-0">
        <Card.Body className="text-center">
          <GalleryCard.Image
            className="product-image w-50"
            src={
              teamClone.team_logo_square_image
                ? `/api/s3/uploads/${teamClone.team_logo_square_image}`
                : productImagePlaceholder
            }
          />
          {rootStore.currentUser && (
            <div className="banner-dropdown-container">
              <Dropdown
                className="cursor-pointer banner-dropdown"
                icon="camera"
                items={
                  <TeamCardDropdown
                    imageName={teamClone.team_logo_square_image}
                    imageType={ETeamImageType.team_logo_square_image}
                    onErrorHandler={onError}
                    onFinishedHandler={onFinished}
                    callBack={updateTeamImages}
                  />
                }
                toggle={false}
              />
            </div>
          )}
        </Card.Body>
      </Card>
      <h5>Header logo</h5>
      <Card className="card-logo-header border-0">
        <Card.Header
          backgroundURL={
            teamClone.team_logo_header
              ? `/api/s3/uploads/${teamClone.team_logo_header}`
              : ''
          }
        >
          {rootStore.currentUser && (
            <div className="banner-dropdown-container">
              <Dropdown
                className="cursor-pointer banner-dropdown"
                icon="camera"
                items={
                  <TeamCardDropdown
                    imageName={teamClone.team_logo_header}
                    imageType={ETeamImageType.team_logo_header}
                    onErrorHandler={onError}
                    onFinishedHandler={onFinished}
                    callBack={updateTeamImages}
                  />
                }
                toggle={false}
              />
            </div>
          )}
        </Card.Header>
      </Card>
      {isAdmin && (
        <>
        <h5>Favicon</h5>
        <Card className="card-favicon border-0">
        <Card.Header
          backgroundURL={
            teamClone.team_favicon
              ? `/api/s3/uploads/${teamClone.team_favicon}`
              : ''
          }
        >
          {rootStore.currentUser && (
            <div className="banner-dropdown-container">
              <Dropdown
                className="cursor-pointer banner-dropdown"
                icon="camera"
                items={
                  <TeamCardDropdown
                    imageName={teamClone.team_favicon}
                    imageType={ETeamImageType.team_favicon}
                    onErrorHandler={onError}
                    onFinishedHandler={onFinished}
                    callBack={updateTeamImages}
                  />
                }
                toggle={false}
              />
            </div>
          )}
        </Card.Header>
      </Card>
        </>
      )}
    </>
  );
};

export default observer(TeamImages);
