import CurrencyInputField from 'components/CurrencyInputField'
import { useFormikContext } from 'formik'
import { NewTeamOnboardingFormValues } from 'modules/team/components/forms/NewTeamOnboardingForm'
import React from 'react'
import Select from 'react-select'
import { Button, Form, Text } from 'tabler-react'
import { object, string } from 'yup'
import { currencyOptions } from '../../../../../components/Team/Form/TeamProfile'

export const currencyValidationSchema = object().shape({
  default_currency: string().required('Currency is required')
})

const CurrencyStep = () => {
  const { errors, touched, values, setFieldValue } =
    useFormikContext<NewTeamOnboardingFormValues>();

  return (
    <>
      <Form.Group label="Currency">
        <Select
          name="default_currency"
          isClearable={true}
          options={currencyOptions}
          onChange={(e) => setFieldValue('default_currency', e.value)}
          value={currencyOptions.find(
            (c) => c.value === values.default_currency
          )}
        />
        <span className="field-error text-danger">
          {errors.default_currency &&
            touched.default_currency &&
            errors.default_currency}
        </span>
      </Form.Group>

      <Form.Group>
        <Form.Switch
          type="checkbox"
          name="is_feature_payments"
          onChange={(e) =>
            setFieldValue('is_feature_payments', e.target.checked)
          }
          checked={values.is_feature_payments ? true : false}
          label="Enable credit card payments (optional)."
        />
      </Form.Group>

      {values.is_feature_payments && (
        <Form.Group>
          <Form.InputGroup>
            <CurrencyInputField
              name="payment_fee_customer"
              placeholder="0.00"
              value={values.payment_fee_customer}
              setFieldValue={setFieldValue}
              prefix=""
              customInput={undefined}
              className="form-control"
            />
            <Form.InputGroupAppend>
              <Button color="secondary" disabled>
                %
              </Button>
            </Form.InputGroupAppend>
          </Form.InputGroup>
          <span className="field-error text-danger">
            {errors.payment_fee_customer && errors.payment_fee_customer}
          </span>
          <Text.Small className="pt-3 text-muted">
            The fee charged to customers
          </Text.Small>
        </Form.Group>
      )}
    </>
  );
};

export default CurrencyStep;
