import React from 'react'
import { Formik } from 'formik'
import { Avatar, Button, Card, Form, Text } from 'tabler-react'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'
import { ADD_COMMENT } from '../../../../../graphql/ADD_COMMENT'
import { useRootStore } from '../../../../../hooks'
import { useGetCoachStudentData } from '../../../../student/hooks/useGetStudentById'

const CommentForm = ({ postId, studentId }) => {
  const { currentUser } = useRootStore()
  const { student, loading } = useGetCoachStudentData({
    student_id: currentUser.student_id,
    skip: !currentUser.student_id
  })
  const [addComment] = useMutation(ADD_COMMENT, {
    onCompleted: () => toast.success('Comment added!'),
    refetchQueries: ['GET_USER_POSTS']
  })

  const avatar = student?.profile_avatar || currentUser.profile_avatar
  const name = student
    ? student.first_name + ' ' + student.last_name
    : currentUser.first_name + ' ' + currentUser.last_name
  if (loading) return null
  return (
    <React.Fragment>
      <Card className="border-0 m-0">
        <Card.Body className="border-0 p-0">
          <Formik
            enableReinitialize={true}
            initialValues={{
              user_post_id: postId,
              content: '',
              student_id: studentId,
              is_public: true
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              await addComment({
                variables: {
                  comment: {
                    ...values
                  }
                }
              })
              setSubmitting(false)
              resetForm()
            }}
          >
            {({ values, handleChange, handleSubmit }) => {
              return (
                <>
                  <div className="mb-2 ml-0">
                    <Avatar
                      size="md"
                      className="mr-3 float-left mb-2"
                      imageURL={
                        (avatar && `/api/s3/uploads/${avatar}`) ||
                        '//www.gravatar.com/avatar?d=mp'
                      }
                    />
                    <strong>{name}</strong>
                    <Text.Small className="d-block text-muted">Join the conversation...</Text.Small>
                  </div>
                  <Form.Input
                    autoFocus
                    type="text"
                    multiline={'true'}
                    placeholder="Add comment..."
                    name="content"
                    rows={values.content ? 2 : 1}
                    onChange={handleChange}
                    value={values.content}
                  />
                  {values.content && (
                    <Button.List className="mt-4 mb-2" align="right">
                      <Button
                        size={'sm'}
                        pill
                        color="gray-dark"
                        type="submit"
                        onClick={() => handleSubmit()}
                        disabled={!values.content}
                      >
                        COMMENT
                      </Button>
                    </Button.List>
                  )}
                </>
              )
            }}
          </Formik>
        </Card.Body>
      </Card>
    </React.Fragment>
  )
}

export default CommentForm
