import React from 'react'
import { Types } from '../../../../types/graphql'
import autoCompleteStyles from '../../../../components/Students/Forms/autocompleteStyles'
import { useAutocomplete } from '../../../../hooks/useAutocomplete'
import AsyncSelect from 'react-select/async'
import { GET_GROUPS_BY_FILTER_QUERY } from '../../../../graphql/GET_GROUPS_BY_FILTER_QUERY'
import { toast } from 'react-toastify'
import { Button, Form } from 'tabler-react'

type GroupsSearchProps = {
  campId: number
  createGroup: (e: any) => void
  addStudentAction: (e: any) => void
  studentId: number
  registrationId?: number
  teamId?: number
  toggleForm: () => void
}
const GroupsSearch = (
  {
    campId,
    createGroup,
    addStudentAction,
    studentId,
    registrationId,
    teamId,
    toggleForm
  }:GroupsSearchProps
) => {

  const { loadOptions } = useAutocomplete({
    query: GET_GROUPS_BY_FILTER_QUERY, campId: campId,minInputLength: 1
  });

  const [group, setGroup] = React.useState<Types.Group | null>(null);
  return (
    <Form.Group className="mb-0">
      <Form.InputGroup>
        <AsyncSelect
          autoFocus={true}
          backspaceRemovesValue={true}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            LoadingIndicator: () => null
          }}
          escapeClearsValue={true}
          getOptionLabel={(option: Types.Group) => (
            <>
              <span className='text-primary'>{option.name + ' '}</span>
              <span className={'text-muted float-right'}>
                <small>{option.discipline}</small>
              </span>
            </>
          )}
          getOptionValue={(option: Types.Group) => option.id}
          isClearable={true}
          loadOptions={loadOptions}
          name="group"
          onChange={(e) => {
            if (!e.id && e.name) {
              createGroup({
                e,
                ...{
                  name: e.name.replace('Create: ', ''),
                  user_registration_id: registrationId,
                  team_id: teamId
                }
              })
            } else {
              if (e.members.find((m) => m.id === studentId))
                toast.warning('Already a member of group')
              else addStudentAction(e)
            }
          }}
          value={
            group
              ? {
                  id: group.id,
                  name: group.name
                }
              : null
          }
          placeholder="Search teams to join, or Create"
          styles={{ ...autoCompleteStyles }}
        />
        <Form.InputGroupAppend>
          <Button
            icon={'x'}
            color="white"
            className="ml-1"
            onClick={toggleForm}
          />
        </Form.InputGroupAppend>
      </Form.InputGroup>
    </Form.Group>
  )
}

export default GroupsSearch
