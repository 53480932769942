import { Grid, StampCard, Text } from 'tabler-react'
import { formatMoney } from 'utils/numberFormat'

const EventRegistrationTotalPendingRevenue = ({ registrations }) => {
  if (registrations.length === 0) {
    return null
  }
  const totals = {
    pendingPaymentsCount: 0,
    pendingPaymentsAmount: 0,
    registration_currency: registrations[0].registration_currency
  }
  registrations.forEach((registration) => {
    totals.pendingPaymentsAmount += registration.paymentDetail.outstanding / 100
    if (registration.paymentDetail.outstanding > 0) {
      totals.pendingPaymentsCount++
    }
  })

  return <EventRegistrationTotalPendingRevenueInner payments={totals} />
}

const EventRegistrationTotalPendingRevenueInner = ({ payments }) => {
  return (
    <Grid.Col sm={6} lg={3} xs={12} className="pl-0">
      <span>
        <StampCard
          className="mb-0"
          color="secondary"
          icon="dollar-sign"
          // Total number of participants that have not paid registration fee in full
          header={
            <Text>
              {payments.pendingPaymentsCount} <small>Have Balance</small>
            </Text>
          }
          // Total amount of registration fees that have not been collected
          footer={
            <Text>
              {formatMoney(
                payments.pendingPaymentsAmount,
                payments.registration_currency
              )}
            </Text>
          }
        />
      </span>
    </Grid.Col>
  )
}
export default EventRegistrationTotalPendingRevenue
