import { useQuery } from '@apollo/client'
import Box from '@material-ui/core/Box'
import CalendarLink from 'components/Calendar/CalendarLink'
import CompetitionGroupsList from 'components/Calendar/CompetitionGroupList'
import EventGroupsList from 'components/Calendar/EventGroupsList'
import { EHostnames } from 'components/constants'
import { EEventTypes } from 'components/enum'
import EventRegistrationListItem from 'components/Event/EventRegistrationListItem'
import { Loading } from 'components/Loading'
import { CAMP_HAS_JUMP_TICKETS } from 'graphql/CAMP_HAS_JUMP_TICKETS'
import { GET_CAMP_BY_ID } from 'graphql/GET_CAMP_BY_ID'
import { GET_CAMP_REGISTRATION_REQUIREMENTS } from 'graphql/GET_CAMP_REGISTRATION_REQUIREMENTS'
import { CAMP_HAS_JUMP_TICKETS as CAMP_HAS_JUMP_TICKETS_TYPE } from 'graphql/types/CAMP_HAS_JUMP_TICKETS'
import { GET_CAMP_BY_ID as GET_CAMP_BY_ID_TYPE } from 'graphql/types/GET_CAMP_BY_ID'
import useGetCurrentUserType, {
  useTeamRoles
} from 'modules/common/hooks/useGetCurrentUserType'
import AddJumpTicketsButton from 'modules/registration/options/components/buttons/AddJumpTicketsButton'
import ResetJumpTicketsButton from 'modules/registration/options/components/buttons/ResetJumpTicketsButton'
import TShirtDetailsButton from 'modules/registration/options/components/buttons/TShirtDetailsButton'
import useGetRegistrationStatus from 'modules/team-registration-status/hooks/useGetRegistrationStatus'
import SendEmailToEventRegisteredUsersButton from 'modules/user/registrations/components/buttons/SendEmailToEventRegisteredUsersButton'
import EventRegistrationTotalConfirmed from 'modules/user/registrations/components/stamps/EventRegistrationTotalConfirmed'
import EventRegistrationTotalPayments from 'modules/user/registrations/components/stamps/EventRegistrationTotalPayments'
import EventRegistrationTotalPendingRevenue from 'modules/user/registrations/components/stamps/EventRegistrationTotalPendingRevenue'
import { useGetCampStudents } from 'modules/user/registrations/hooks/useGetRegisteredStudents'
import React, { useCallback, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router'
import {
  Alert,
  Button,
  Card,
  Dimmer,
  Dropdown,
  Form,
  Grid,
  Icon,
  Table
} from 'tabler-react'
import { GET_CAMP_COHOSTS } from '../../graphql/GET_CAMP_COHOSTS'
import { useRootStore } from '../../hooks'
import useGetEventOrganizers from '../../modules/user/roles/hooks/useGetEventOrganizers'
import PageNoAccess from '../../pages/PageNoAccess'
import { Types } from '../../types/graphql'
import BulkEmailModal from './Modals/BulkEmailModal'
import { BulkLogPaymentModal } from './Modals/BulkPaymentLogForm'
import { BulkStatusChangeModal } from './Modals/BulkStatusModalForm'
import GroupFinance from '../../modules/camp/components/GroupFinance'
import { GET_STRIPE_PUBLIC_KEY } from '../../graphql/GET_STRIPE_PUBLIC_KEY'
import { GET_PAYMENT_FEE_BY_TEAM_ID as GET_PAYMENT_FEE_BY_TEAM_ID_TYPE } from '../../graphql/types/GET_PAYMENT_FEE_BY_TEAM_ID'
import { GET_PAYMENT_FEE_BY_TEAM_ID } from '../../graphql/GET_PAYMENT_FEE_BY_TEAM_ID'
import EventController from 'modules/camp/components/EventController'
import EventFinance from 'modules/camp/components/EventFinance'
import GroupPaymentsCard from '../../modules/group/components/GroupPaymentsCard'
import GroupCountCard from '../../modules/group/components/GroupCountCard'
import RegistrationListCSVButton from '../../modules/camp/components/buttons/RegistrationListCSVButton'
import EventHistoryCsvButton from '../../modules/camp/components/buttons/EventHistoryCSVButton'
import { EUserRoles } from '../User/userRoles'

const EventRegistrationListSkeleton = () => {
  return (
    <Box>
      <Box pb={2}>
        <Skeleton height={65} width="100%" />
      </Box>
    </Box>
  )
}

const EventRegistrationList = () => {
  const { currentCoachTeam, domainData, currentUser } = useRootStore()
  const { campId } = useParams<{ campId: string }>()
  const { isAdmin, isCoachSuperAdmin, isOrg } = useGetCurrentUserType()
  const { roles } = useTeamRoles()
  const [selectedParticipants, setSelectedParticipants] = useState<
    Types.CampStudentListItem[]
  >([])
  const [isBulkStatusChangeModalOpen, setIsBulkStatusChangeModalOpen] =
    useState<boolean>(false)
  const [isBulkLogPaymentModalOpen, setIsBulkLogPaymentModalOpen] =
    useState<boolean>(false)
  const [isBulkEmailModalOpen, setIsBulkEmailModalOpen] =
    useState<boolean>(false)
  const [statusFilter, setStatusFilter] = useState<string>('')
  const [groupStatusFilter, setGroupStatusFilter] = useState<string>('Active')
  const [report, setReport] = useState<string>('list')
  const hasGroupAccess =
    isAdmin || isCoachSuperAdmin || (isOrg && (roles.find((role) => role.id === EUserRoles.finance) ||
                                      roles.find((role) => role.id === EUserRoles.controller) ||
                                      roles.find((role) => role.id === EUserRoles.admin)))
  const hasFinanceAccess =
    isAdmin || isCoachSuperAdmin || (isOrg && (roles.find((role) => role.id === EUserRoles.finance) ||
                                      roles.find((role) => role.id === EUserRoles.admin)))
  const hasControllerAccess =
    isAdmin || isCoachSuperAdmin || (isOrg && (roles.find((role) => role.id === EUserRoles.controller) ||
                                      roles.find((role) => role.id === EUserRoles.admin)))

  const { data: campData, loading: campLoading } =
    useQuery<GET_CAMP_BY_ID_TYPE>(GET_CAMP_BY_ID, {
      variables: {
        campId: Number(campId)
      }
    })
  const { loading: loadingTeams, data: dataTeams } = useQuery(
    GET_CAMP_COHOSTS,
    {
      variables: {
        campId: Number(campId)
      }
    }
  )

  const isCurrentTeamCoHost = useMemo(() => {
    if (loadingTeams || !dataTeams?.getCampCoHosts) return false
    return dataTeams?.getCampCoHosts
      .filter((team) => team.is_active)
      .some((team) => team.id === currentCoachTeam?.id)
  }, [dataTeams, loadingTeams, currentCoachTeam])

  const { data: campJumpTickets } = useQuery<CAMP_HAS_JUMP_TICKETS_TYPE>(
    CAMP_HAS_JUMP_TICKETS,
    {
      variables: {
        campId: Number(campId)
      }
    }
  )

  const campHasJumpTickets = useMemo(() => {
    return campJumpTickets?.campHasJumpTickets
  }, [campJumpTickets?.campHasJumpTickets])

  const { data, loading } = useGetCampStudents({ campId })

  const { data: campRequirementsData, loading: campRequirementsLoading } =
    useQuery(GET_CAMP_REGISTRATION_REQUIREMENTS, {
      variables: {
        team_id: Number(campData?.getCampById.team_id),
        camp_id: Number(campId)
      },
      skip: !campData?.getCampById.team_id
    })

  const campRequirements = useMemo(() => {
    if (campRequirementsLoading) return []

    return campRequirementsData?.campRegistrationRequirements
      .filter((r) => r.is_exist)
      .map((r) => r.requirement_type_slug)
  }, [campRequirementsData, campRequirementsLoading])

  const isShirtRequired = useMemo(() => {
    if (campRequirementsLoading) return false

    return !!campRequirementsData?.campRegistrationRequirements.find(
      (r) => r.is_exist && r.requirement_type_slug === 'shirt_size'
    )
  }, [campRequirementsData, campRequirementsLoading])

  const isDisciplineRequired = useMemo(() => {
    if (campRequirementsLoading) return false

    return !!campRequirements?.find((r) => r.slug === 'discipline')
  }, [campRequirementsLoading, campRequirements])

  const { teamStatusOptions } = useGetRegistrationStatus(
    Number(campData?.getCampById.team_id)
  )

  const participantCountByStatus = useMemo(() => {
    if (data && teamStatusOptions) {
      return teamStatusOptions.map((status) => {
        const count = data.filter(
          (student) => student.status === status.name
        ).length
        return { ...status, count }
      })
    }
    return []
  }, [data, teamStatusOptions])

  const confirmedStatus =
    teamStatusOptions.find(
      (status) => status.type === 'Confirmed' || status.name === 'Confirmed'
    ) ?? null
  const pendingStatus =
    teamStatusOptions.find(
      (status) => status.type === 'Pending' || status.name === 'Pending'
    ) ?? null
  const acceptedStatus =
    teamStatusOptions.find((status) => status.name === 'Accepted') ?? null

  const waitlistStatus = teamStatusOptions.find(
    (status) => status.type === 'Waitlist' || status.name === 'Waitlist'
  )

  const registeredStudents = useMemo(() => {
    if (!loading && data) {
      switch (statusFilter) {
        case 'confirmed':
          return data.filter(
            (student) => student.status === confirmedStatus.name
          )
        case 'pending':
          return data.filter((student) => student.status === pendingStatus.name)
        case 'accepted':
          return data.filter(
            (student) => student.status === acceptedStatus?.name
          )
        case 'waitlist':
          return data.filter(
            (student) => student.status === waitlistStatus.name
          )
        case 'rejected':
          return data.filter(
            (student) =>
              student.status === 'Rejected' || student.status === 'Withdrawn'
          )
        case 'others':
          return data.filter(
            (student) =>
              ![
                'Rejected',
                'Withdrawn',
                'Waitlist',
                confirmedStatus.name,
                pendingStatus.name
              ].includes(student.status)
          )
        default:
          return data.map((student) => student)
      }
    }

    return []
  }, [data, statusFilter])

  const handleToggleSelected = (id: number) => {
    if (
      selectedParticipants?.find((student) => {
        return student.user_registrations_id === id
      })
    ) {
      setSelectedParticipants(
        selectedParticipants.filter((p) => p.user_registrations_id !== id) ?? []
      )
    } else {
      setSelectedParticipants([
        ...selectedParticipants,
        registeredStudents.find(
          (student) => student.user_registrations_id === id
        )
      ])
    }
  }
  const [selectAll, setSelectAll] = useState<boolean>(false)

  const handleToggleSelectAll = (selectedAll) => {
    if (selectedAll) {
      setSelectedParticipants(registeredStudents)
    } else {
      setSelectedParticipants([])
    }
  }

  const registrationList = useMemo(
    () =>
      data.map((regStudent) => {
        const {
          id,
          email,
          first_name,
          last_name,
          phone_country_code,
          phone_number,
          registration_code,
          rating,
          status,
          uspa
        } = regStudent

        const uspaData =
          domainData.key === EHostnames.SKYCRU ? { uspa: uspa?.uspaStatus } : {}

        return {
          id,
          first_name,
          last_name,
          status,
          email,
          phone_country_code,
          phone_number,
          registration_code,
          rating,
          paid_status:
            regStudent.paymentDetail.outstanding > 0 ? 'Pending' : 'Paid',
          ...uspaData
        }
      }) ?? [],
    [data, campRequirements]
  )

  const { isOrganizer, eventOrganizersDataLoading } = useGetEventOrganizers({
    eventId: Number(campId)
  })

  const hasAccess = useCallback(() => {
    return (
      isOrganizer ||
      isCurrentTeamCoHost ||
      (currentCoachTeam &&
        currentCoachTeam?.id === Number(campData?.getCampById?.team_id)) ||
      isAdmin
    )
  }, [currentCoachTeam, campData, isAdmin, isOrganizer, isCurrentTeamCoHost])

  const { loading: loading1, data: stripeData } = useQuery(
    GET_STRIPE_PUBLIC_KEY
  )
  const stripeKey = stripeData?.getStripePublicKey

  const { loading: loading2, data: paymentData } =
    useQuery<GET_PAYMENT_FEE_BY_TEAM_ID_TYPE>(GET_PAYMENT_FEE_BY_TEAM_ID, {
      variables: {
        teamId: Number(campData?.getCampById?.team_id)
      },
      skip: !campData?.getCampById?.team_id
    })

  const paymentFee =
    paymentData?.getPaymentFeeByTeamId.payment_fee_customer / 100

  if (!eventOrganizersDataLoading && !campLoading && !hasAccess()) {
    return <PageNoAccess teamId={Number(campData?.getCampById?.team_id)} />
  }
  if (loading1 || loading2) {
    return <Loading />
  }
  return (
    <>
      <Helmet>
        <title>
          {domainData.name +
            ' | Participants: ' +
            campData?.getCampById.camp_name}
        </title>
      </Helmet>
      <Dimmer
        active={eventOrganizersDataLoading || campLoading}
        loader={eventOrganizersDataLoading || campLoading}
      >
        <div className="container">
          <Grid.Row>
            {!!participantCountByStatus &&
            participantCountByStatus.length > 0 ? (
              <EventRegistrationTotalConfirmed
                participantCountByStatus={participantCountByStatus}
                confirmedOnly={report !== 'list'}
                onClick={(param) =>
                  setStatusFilter((prev) => (prev === param ? '' : param))
                }
              />
            ) : (
              <Loading />
            )}

            {report === 'finance' && (
              <>
                {!campData.getCampById.registration_groups && (
                  <>
                    <EventRegistrationTotalPayments registrations={data} />
                    <EventRegistrationTotalPendingRevenue
                      registrations={data.filter(
                        (student) =>
                          student.status === confirmedStatus.name ||
                          student.status === acceptedStatus?.name
                      )}
                    />
                  </>
                )}

                {!!campData.getCampById.registration_groups && (
                  <GroupPaymentsCard campId={Number(campId)} />
                )}
              </>
            )}

            {report === 'groups' && (
              <>
                <GroupCountCard
                  campId={Number(campId)}
                  teamId={campData?.getCampById.team_id}
                  setGroupFilter={setGroupStatusFilter}
                />
              </>
            )}
          </Grid.Row>
          {/* // save for later implementation
        <FeatureAccessGate feature="feature_alpha">
          <Grid.Row>
            <Text>
              <strong>Loads & Jump Tickets</strong>
            </Text>
          </Grid.Row>
          <Grid.Row className="mt-2">
            <Grid.Col sm={6} lg={3} xs={12} className="px-0 mb-2">
              <StampCard
                className="mb-0"
                color="info"
                icon="users"
                header={
                  <Text>
                    {"Day 1"} <small>Aug 11</small>
                  </Text>
                }
                footer={<Text>1 Load | 14 Jump Tickets | $980</Text>}
              />
            </Grid.Col>
            <Grid.Col sm={6} lg={3} xs={12} className="px-0 mb-2">
              <StampCard
                className="mb-0"
                color="info"
                icon="users"
                header={
                  <Text>
                    {"Day 2"} <small>Aug 11</small>
                  </Text>
                }
                footer={<Text>1 Load | 14 Jump Tickets | $980</Text>}
              />
            </Grid.Col>
            <Grid.Col sm={6} lg={3} xs={12} className="px-0 mb-2">
              <StampCard
                className="mb-0"
                color="info"
                icon="users"
                header={
                  <Text>
                    {"Day 3"} <small>Aug 11</small>
                  </Text>
                }
                footer={<Text>1 Load | 14 Jump Tickets | $980</Text>}
              />
            </Grid.Col>
            <Grid.Col sm={6} lg={3} xs={12} className="px-0 mb-2">
              <StampCard
                className="mb-0"
                color="info"
                icon="users"
                header={
                  <Text>
                    {"Day 4"} <small>Aug 11</small>
                  </Text>
                }
                footer={<Text>1 Load | 14 Jump Tickets | $980</Text>}
              />
            </Grid.Col>
          </Grid.Row>
        </FeatureAccessGate>
        */}
          <Grid.Row className="mt-4">
            <Card>
              <Card.Header>
                <Card.Title>
                  <strong>
                    <CalendarLink
                      eventName={campData?.getCampById.camp_name}
                      eventId={Number(campId)}
                    />
                  </strong>
                </Card.Title>
                <Card.Options>
                  <Button
                    icon="list"
                    color="white"
                    className={
                      report === 'list' ? 'text-primary' : 'text-muted'
                    }
                    onClick={() => setReport('list')}
                  >
                    LIST
                  </Button>
                  <Button
                    icon="users"
                    color="white"
                    className={
                      report === 'groups' ? 'text-primary' : 'text-muted'
                    }
                    // onClick={() => history.push(`/event/groups/${campId}`)}
                    onClick={() => setReport('groups')}
                    disabled={
                      !isAdmin && !isCoachSuperAdmin && isOrg && !hasGroupAccess
                    }
                  >
                    {domainData?.key === EHostnames.FAIRFIGHTS
                      ? 'FIGHT CARD'
                      : 'GROUPS'}
                  </Button>

                  <Button
                    icon="award"
                    color="white"
                    className={
                      report === 'controller' ? 'text-primary' : 'text-muted'
                    }
                    onClick={() => setReport('controller')}
                    // DISABLED IF CO-HOST, OR IF ORG AND NOT CONTROLLER OR ADMIN ROLE
                    disabled={
                      !isAdmin &&
                      !isCoachSuperAdmin &&
                      isOrg &&
                      !hasControllerAccess
                    }
                  >
                    {isOrg ? 'CONTROLLER' : 'CREDENTIALS'}
                  </Button>
                  {domainData?.key !== EHostnames.FAIRFIGHTS && (
                    <Button
                      icon="dollar-sign"
                      color="white"
                      className={
                        report === 'finance' ? 'text-primary' : 'text-muted'
                      }
                      onClick={() => setReport('finance')}
                      // DISABLED IF CO-HOST, OR IF ORG AND NOT FINANCE SECRETARY OR ADMIN ROLE
                      disabled={
                        !isAdmin &&
                        !isCoachSuperAdmin &&
                        isOrg &&
                        !hasFinanceAccess
                      }
                    >
                      FINANCE
                    </Button>
                  )}
                  <Dropdown
                    key="dropdown"
                    color="white"
                    position="bottom-end"
                    arrow={true}
                    arrowPosition="right"
                    dropdownProps={{
                      className: 'btn-white pt-2'
                    }}
                    triggerContent={<Icon name="more-vertical" />}
                    items={
                      <>
                        {isCoachSuperAdmin && (
                          <SendEmailToEventRegisteredUsersButton />
                        )}
                        {campHasJumpTickets && (
                          <>
                            <ResetJumpTicketsButton />
                            <Dropdown.ItemDivider />
                          </>
                        )}
                        {!campHasJumpTickets && (
                          <>
                            <AddJumpTicketsButton />
                            <Dropdown.ItemDivider />
                          </>
                        )}
                        {isShirtRequired && (
                          <TShirtDetailsButton
                            registrationList={registrationList}
                          />
                        )}
                        <Dropdown.Item
                          icon="upload"
                          color="white"
                          className="cursor-pointer text-muted"
                          onClick={() => {
                            window.open(
                              'mailto:import_data@skycru.com?subject=IMPORT%20CSV&body=Please%20attach%20your%20CSV%20file%20to%20this%20email.',
                              '_blank'
                            )
                          }}
                        >
                          IMPORT DATA (CSV)
                        </Dropdown.Item>
                      </>
                    }
                  />
                </Card.Options>
              </Card.Header>
              {report === 'groups' && (
                <>
                  {campData?.getCampById.event_type_id ===
                  EEventTypes.competition ? (
                    <CompetitionGroupsList
                      groupStatus={groupStatusFilter}
                      campId={campId}
                      campName={campData?.getCampById.camp_name}
                      teamId={campData?.getCampById.team_id}
                      students={campData?.getCampById.registered_students
                        .filter((s) =>
                          ['confirmed', 'accepted', 'pending'].includes(
                            s.status.toLowerCase()
                          )
                        )
                        .sort((a, b) =>
                          a.first_name.localeCompare(b.first_name)
                        )}
                    />
                  ) : (
                    <EventGroupsList
                      campId={campId}
                      isDisciplineRequired={isDisciplineRequired}
                      campName={campData?.getCampById.camp_name}
                      teamId={campData?.getCampById.team_id}
                      students={campData?.getCampById.registered_students
                        .filter(
                          (s) =>
                            s.status_type &&
                            s.status_type.toLowerCase() === 'confirmed'
                        )
                        .sort((a, b) =>
                          a.first_name.localeCompare(b.first_name)
                        )}
                    />
                  )}
                </>
              )}
              {report === 'controller' && (
                <EventController
                  campData={campData.getCampById}
                  confirmedStatus={confirmedStatus}
                  registeredStudents={registeredStudents}
                  campRequirements={campRequirements}
                />
              )}

              {report === 'finance' && (
                <>
                  {campData.getCampById.registration_groups ? (
                    <GroupFinance
                      campId={campId}
                      teamId={campData?.getCampById.team_id}
                    />
                  ) : (
                    <EventFinance
                      campData={campData.getCampById}
                      registeredStudents={registeredStudents}
                    />
                  )}
                </>
              )}
              {report === 'list' && (
                <>
                  <Card.Body>
                    <Grid.Row className="p-3">
                      <Grid.Col width={1} className="pt-2">
                        <Form.Label>
                          <input
                            id={`selectAll`}
                            name={'select_all'}
                            type="checkbox"
                            checked={selectAll}
                            onClick={() => {
                              setSelectAll(!selectAll)
                              handleToggleSelectAll(!selectAll)
                            }}
                            onChange={() => {}}
                            className="float-left pb-2"
                          />
                        </Form.Label>
                      </Grid.Col>
                      <Grid.Col width={11} className="text-right p-0">
                        <Button
                          outline={selectedParticipants.length !== 0}
                          icon="circle"
                          color={
                            selectedParticipants.length !== 0
                              ? 'secondary'
                              : 'white'
                          }
                          size={'sm'}
                          className="mr-2"
                          onClick={() => {
                            setIsBulkStatusChangeModalOpen(true)
                          }}
                          disabled={selectedParticipants.length === 0}
                        >
                          STATUS
                        </Button>
                        <>
                          <Button
                            outline={selectedParticipants.length !== 0}
                            icon="credit-card"
                            color={
                              selectedParticipants.length !== 0
                                ? 'secondary'
                                : 'white'
                            }
                            size={'sm'}
                            className="mr-2"
                            onClick={() => {
                              setIsBulkLogPaymentModalOpen(true)
                            }}
                            disabled={selectedParticipants.length === 0}
                          >
                            PAYMENTS
                          </Button>

                          <Button
                            outline={selectedParticipants.length !== 0}
                            icon="mail"
                            color={
                              selectedParticipants.length !== 0
                                ? 'secondary'
                                : 'white'
                            }
                            size={'sm'}
                            className="mr-2"
                            onClick={() => {
                              setIsBulkEmailModalOpen(true)
                            }}
                            disabled={selectedParticipants.length === 0}
                          >
                            EMAIL
                          </Button>
                        </>
                        {!isAdmin && (
                          <Dropdown
                            key="dropdown"
                            position="bottom-end"
                            arrow={true}
                            arrowPosition="right"
                            dropdownProps={{
                              className: 'btn-sm btn-white'
                            }}
                            triggerContent={<Icon name="download" />}
                            items={
                              <>
                                <Dropdown.Item className="cursor-pointer m-0">
                                  <RegistrationListCSVButton
                                    campId={campId}
                                    teamId={campData?.getCampById.team_id}
                                  />
                                </Dropdown.Item>
                                <Dropdown.Item className="cursor-pointer m-0">
                                  <EventHistoryCsvButton
                                    teamId={currentCoachTeam?.id}
                                    campId={campId}
                                    start={campData?.getCampById.start}
                                  />
                                </Dropdown.Item>
                              </>
                            }
                          />
                        )}
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row className="mb-0 mt-3">
                      <Grid.Col width={12}>
                        {loading ? (
                          <EventRegistrationListSkeleton />
                        ) : (
                          <Table
                            responsive
                            className="table-vcenter text-nowrap"
                          >
                            <Table.Body>
                              {registeredStudents.length <= 0 && (
                                <Alert type="info text-center">
                                  <strong>No Data</strong>
                                </Alert>
                              )}
                              {registeredStudents.map(
                                (student: Types.CampStudentListItem) => (
                                  <Table.Row
                                    key={student.user_registrations_id}
                                  >
                                    <EventRegistrationListItem
                                      scorecard={
                                        campData?.getCampById.score_cards
                                      }
                                      student={student}
                                      teamId={Number(
                                        campData?.getCampById.team_id
                                      )}
                                      selected={
                                        !!selectedParticipants?.find(
                                          (participant) =>
                                            participant.student_id ===
                                            student.student_id
                                        )
                                      }
                                      handleToggleSelected={
                                        handleToggleSelected
                                      }
                                      stripeKey={stripeKey}
                                      paymentFee={paymentFee}
                                    />
                                  </Table.Row>
                                )
                              )}
                            </Table.Body>
                          </Table>
                        )}
                      </Grid.Col>
                    </Grid.Row>
                  </Card.Body>
                  {selectedParticipants && selectedParticipants.length > 0 && (
                    <>
                      <BulkStatusChangeModal
                        isOpen={isBulkStatusChangeModalOpen}
                        toggle={() => setIsBulkStatusChangeModalOpen(false)}
                        students={selectedParticipants}
                        teamId={Number(campData?.getCampById.team_id)}
                      />
                      <BulkLogPaymentModal
                        isOpen={isBulkLogPaymentModalOpen}
                        toggle={() => setIsBulkLogPaymentModalOpen(false)}
                        students={selectedParticipants}
                        currency={campData?.getCampById.registration_currency}
                        teamId={Number(campData?.getCampById.team_id)}
                      />
                      <BulkEmailModal
                        isOpen={isBulkEmailModalOpen}
                        toggle={() => setIsBulkEmailModalOpen(false)}
                        students={selectedParticipants}
                        teamId={Number(campData?.getCampById.team_id)}
                        campId={Number(campId)}
                      />
                    </>
                  )}
                </>
              )}
            </Card>
          </Grid.Row>
        </div>
      </Dimmer>
    </>
  )
}



export default EventRegistrationList
