import { EUserTypes } from 'components/User/userTypes'
import { useRootStore } from 'hooks'
import AcademyNav from '../fairfights/AcademyNav'
import AdminNav from '../AdminNav'
import CoachNav from '../CoachNav'
import DZMNav from '../DZMNav'
import EventsNav from '../EventsNav'
import ManufacturerNav from '../ManufacturerNav'
import NacNav from '../NacNav'
import OrgNav from '../OrgNav'
import PromoterNav from '../fairfights/PromoterNav'
import RiggerNav from '../RiggerNav'
import ServicesNav from '../ServicesNav'
import StudentNav from '../StudentNav'
import VisitorNav from '../VisitorNav'
import WTMNav from '../WTMNav'

const NavViews = {
  [EUserTypes.admin]: AdminNav,
  [EUserTypes.coach]: CoachNav,
  [EUserTypes.dzm]: DZMNav,
  [EUserTypes.events]: EventsNav,
  [EUserTypes.manufacturer]: ManufacturerNav,
  [EUserTypes.nac]: NacNav,
  [EUserTypes.org]: OrgNav,
  [EUserTypes.rigger]: RiggerNav,
  [EUserTypes.services]: ServicesNav,
  [EUserTypes.student]: StudentNav,
  [EUserTypes.wtm]: WTMNav,
// FAIRFIGHTS
  [EUserTypes.academy]: AcademyNav,
  [EUserTypes.promoter]: PromoterNav,
  visitor: VisitorNav
}

const SkycruNav = () => {
  const { currentUser, currentCoachTeam } = useRootStore()

  const NavView =
    (currentUser &&
      NavViews[
        [EUserTypes.admin, EUserTypes.student].includes(
          currentUser?.type as EUserTypes
        )
          ? currentUser?.type
          : currentCoachTeam?.coach_business_type
      ]) ??
    NavViews.visitor

  return <NavView />
}

export default SkycruNav
