import gql from 'graphql-tag'

export const GET_USER_REGISTRATIONS_BY_IDS = gql`
  query GET_USER_REGISTRATIONS_BY_IDS($userRegistrationsId: Int!) {
    getUserRegistrationsByIds(userRegistrationsId: $userRegistrationsId) {
      burble_id
      camp_fee_type
      camp_fees
      camp_id
      camp_name
      campLocation
      campScoreCards
      campTypeId
      eventTypeId
      created_on
      country
      default_jump_count
      default_jump_price
      discipline
      disciplineId
      jump_quantity
      email
      end
      feature_payments
      student_id
      first_name
      jump_count
      last_name
      location
      org_id
      orgName
      paypal
      profile_picture
      profile_avatar
      registration_code
      registration_currency
      rating
      reach
      registration_groups
      role
      roleId
      sanction_fee
      sanction_fee_currency
      sanction_fee_converted
      sanction_fee_rate
      slot_duration
      sportTypeId
      start
      stance
      status
      team_id
      team_currency
      team_name
      teamStripeAccountExists
      tunnel_time
      user_registrations_id
      userScoreCard
      phone_number
      phone_country_code
      venmo
      weight
      height
      dob
      shirt_size_id

      confirmedSlots {
        start
        end
        user_duration
        slot_option {
          id
          name
          description
          option_value
          option_fee_type_id
        }
        slotCoach {
          first_name
          last_name
        }
        registration_option {
          id
          name
          description
          option_value
          option_fee_type_id
        }
      }

      payments {
        id
        amount
        total
        fees
        processed_by
        manual_payment
        status
        created_on
        coach_first_name
        coach_last_name
        student_first_name
        student_last_name
      }

      paymentDetail {
        total
        outstanding
        totalPaid
        totalFees
        totalComp
        sanctionCurrency
        sanctionFee
        sanctionFeeCount
        sanctionFeeRaw
        convertionRate
      }

      totalTime {
        total_duration
      }
      user_registration_options {
        id
        registration_option_id
        name
        option_value
        quantity
        fee_type
      }
      orgCredentials {
        id
        org_id
        org_name
        org_slug
        student_id
        membership
        expires
        nac_id
        nac_name
        nac_slug
        license
        license_number
        rating
        verified_on
        verified_by
        created_on
        image
      }
    }
  }
`
