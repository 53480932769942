import Modal from 'components/Modal'
import EventOrganizersSelectForm from 'modules/user/roles/components/forms/EventOrganizersSelectForm'
import React, { useState } from 'react'
import { Button, Text } from 'tabler-react'

const EventOrganizersButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  return (
    <>
      <Button
        icon="users"
        color="white"
        className="text-muted"
        onClick={toggleModal}
      >
        Roles
      </Button>
      <Modal
        title="Event Roles"
        content={<EventOrganizersSelectForm />}
        open={isModalOpen}
        onClose={toggleModal}
        actions={
          <Text.Small className="text-left text-muted">Request the email from the organizer you want to add. If you can't find a specific user, contact SUPPORT.</Text.Small>
        }
      />
    </>
  )
}

export default EventOrganizersButton
