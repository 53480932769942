import React, { useMemo } from 'react'
import UserCredentialsForm from '../../../../components/User/Forms/UserCredentialsForm'
import useGetCurrentUserType from '../../../common/hooks/useGetCurrentUserType'
import {
  useGetStudentOrgCredentials,
  useGetUserMerits
} from '../../../user/hooks/merits'
import { useQuery } from '@apollo/client'
import { GET_SPORTS_BY_STUDENT_ID as GET_SPORTS_BY_STUDENT_ID_TYPE } from '../../../../graphql/types/GET_SPORTS_BY_STUDENT_ID'
import { GET_SPORTS_BY_STUDENT_ID } from '../../../../graphql/GET_SPORTS_BY_STUDENT_ID'
import { Types } from '../../../../types/graphql'
import { BASE_ORG_ID } from '../../../../components/constants'
import { Button } from 'tabler-react'

interface Props {
  // Define your component's props here
  studentId: number
  toggle?: () => void
  isOpen: boolean
  first_name: string
  last_name: string
  email: string
}

const AddMembershipFormContainer = ({
  toggle,
  studentId,
  isOpen,
  first_name,
  last_name,
  email
}: Props) => {
  const { isAdmin, isCoachSuperAdmin, isStudent } = useGetCurrentUserType()
  const {
    memberLicense,
    isMemberLicenseExpired,
    sortedABCDLicenses,
    memberUpdated,
    memberEmail,
    memberName,
    hasLifetimeMembership,
    meritsLoading,
    merits,
    coachLicense,
    otherMeritsWithExpiration,
    isCoachLicenseExpired,
    sortedOtherLicenses,
    burble
  } = useGetUserMerits({ studentId })

  const { studentManualCredentials, teamOrgs, credentialsLoading } =
    useGetStudentOrgCredentials(studentId)

  const { data: studentSports, loading: studentSportsLoading } =
    useQuery<GET_SPORTS_BY_STUDENT_ID_TYPE>(GET_SPORTS_BY_STUDENT_ID, {
      variables: {
        student_id: studentId
      },
      skip: !isStudent
    })

  const hasBaseSport = useMemo(() => {
    if (!studentSportsLoading && studentSports) {
      return !!studentSports?.getSportsByStudentId.find(
        (sport) => sport.is_active && sport.name === 'BASE'
      )
    }

    return false
  }, [studentSports, studentSportsLoading])

  const orgs = useMemo(
    () => {
      let orgFiltered
      const defaultOrg = {
        __typename: 'TeamOrg',
        org_id: null,
        slug: 'USPA',
        name: 'USPA (Merits)',
        image: '',
        exist: true,
        team_id: null,
        is_primary: false
      }
      if (!credentialsLoading && teamOrgs) {
        if (!isAdmin && !isStudent) {
          orgFiltered = teamOrgs.filter((teamOrg: Types.TeamOrg) => {
            if (memberLicense && Number(teamOrg.org_id) === 1 && !isAdmin) {
              return false
            }

            if (Number(teamOrg.org_id) === BASE_ORG_ID) {
              return hasBaseSport
            }

            if (
              studentManualCredentials &&
              studentManualCredentials.length > 0
            ) {
              if (
                studentManualCredentials.some(
                  (oc) => oc.org_id === teamOrg.org_id
                ) ||
                teamOrg.exist
              ) {
                return true
              }
            }

            return teamOrg.exist
          })

          if (memberLicense) orgFiltered.unshift(defaultOrg)

          return orgFiltered
        }

        orgFiltered = teamOrgs.filter((teamOrg: Types.TeamOrg) => {
          if (memberLicense && Number(teamOrg.org_id) === 1) {
            return false
          }

          if (isAdmin || isStudent) {
            return true
          }

          return teamOrg.exist
        })

        if (memberLicense) orgFiltered.unshift(defaultOrg)

        return orgFiltered
      }

      return []
    },
    // Eslint-disable-next-line
    [
      credentialsLoading,
      studentManualCredentials,
      isAdmin,
      isStudent,
      memberLicense
    ]
  )

  const displayEmailMerits = useMemo(() => {
    if (!studentManualCredentials) return false

    const hasManualUSPA = studentManualCredentials.some(
      (oc) => Number(oc.org_id) === 1 && oc.membership
    )
    return (
      (isAdmin || isCoachSuperAdmin) &&
      orgs.find((o) => Number(o.org_id) === 1) &&
      hasManualUSPA &&
      !memberLicense
    )
  }, [
    studentManualCredentials,
    memberLicense,
    orgs,
    isAdmin,
    isCoachSuperAdmin
  ])

  const params = {
    memberLicense,
    isMemberLicenseExpired,
    sortedABCDLicenses,
    memberUpdated,
    memberEmail,
    memberName,
    hasLifetimeMembership,
    meritsLoading,
    merits,
    coachLicense,
    otherMeritsWithExpiration,
    isCoachLicenseExpired,
    sortedOtherLicenses,
    studentManualCredentials,
    orgs,
    credentialsLoading,
    student_id: studentId,
    first_name,
    last_name,
    email
  }
  return (
    <>
      <UserCredentialsForm params={params} />
      <Button.List>
        <Button
          onClick={toggle}
          color={'white'}
          size={'sm'}
          pill
          className={'float-right'}
        >
          CLOSE
        </Button>
      </Button.List>
    </>
  )
}

export default AddMembershipFormContainer
