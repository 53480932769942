import React, { useState } from 'react'
import CommentForm from '../forms/CommentForm'
import CommentItem from '../CommentItem'
import { Alert, Card, Grid } from 'tabler-react'
import { useRootStore } from '../../../../../hooks'
import useGetCurrentUserType from '../../../../common/hooks/useGetCurrentUserType'
import Modal from '../../../../../components/Modal'

const UserPostCommentModal = ({ postId, comments, toggle, isOpen }) => {
  const { currentUser } = useRootStore()
  const { isAdmin, isCoachAdmin, isCoachSuperAdmin, isStudent } =
    useGetCurrentUserType()
  const hasStudentId = isStudent || currentUser.student_id
  const [showCommentForm, setShowCommentForm] = useState(false)
  return (
    <Modal
      title="Comments"
      open={isOpen}
      onClose={toggle}
      content={
        <Card.Body className="p-0">
          <Grid.Row>
            <Grid.Col xs={12}>
              <div>
                {comments.length <= 0 && !showCommentForm && (
                  <Alert type="info" className="text-center">No Comments</Alert>
                )}
                {comments.length > 0 &&
                  !showCommentForm &&
                  comments.map((comment) => {
                    return <CommentItem comment={comment} key={comment.id} />
                  })}
              </div>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      }
      actions={
        <>
          {hasStudentId && (
            <CommentForm
              studentId={isStudent ? currentUser.id : currentUser.student_id}
              postId={postId}
            />
          )}
        </>
      }
    />
  )
}

export default UserPostCommentModal
