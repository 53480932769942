import React, { useContext } from 'react'
import { Formik } from 'formik'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Button, Text } from 'tabler-react'
import Modal from '../../../../components/Modal'
import FormField from '../../../../components/FormField'
import { SEND_STUDENT_ACTIVATION_EMAIL } from '../../../../graphql/SEND_STUDENT_ACTIVATION_EMAIL'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { GET_EMAIL_VALIDATION } from '../../../../graphql/GET_EMAIL_VALIDATION'
import { UserStoreContext } from '../../../../contexts/userStoreContext'
import { UPDATE_USER_REGISTRATION } from '../../../../graphql/UPDATE_USER_REGISTRATION'
import { EDIT_GROUP_MEMBER } from '../../../../graphql/EDIT_GROUP_MEMBER'

const sendActivationSchema = Yup.object().shape({
  email: Yup.string().email().required('This field is required.')
})

interface SendActivationEmailModalProps {
  isOpen: boolean
  onClose: () => void
  student: any
  userRegistrationId?: string
  groupId?: string
}

const SendActivationEmailModal = ({
  isOpen,
  onClose,
  student,
  userRegistrationId,
  groupId
}: SendActivationEmailModalProps) => {
  const [sendActivation] = useMutation(SEND_STUDENT_ACTIVATION_EMAIL, {
    onCompleted: () => toast.success('Activation email sent.'),
    refetchQueries: ['GET_STUDENTS_ON_DASHBOARD']
  })
  const userStore = useContext(UserStoreContext)
  const [updateRegistration] = useMutation(UPDATE_USER_REGISTRATION)
  const [updateGroupMember] = useMutation(EDIT_GROUP_MEMBER)
  const [validateEmail, { data: emailData, loading: validating }] =
    useLazyQuery(GET_EMAIL_VALIDATION, {
      fetchPolicy: 'network-only',
      onCompleted: (result) => {
        if (result.validateEmail) {
          userStore.loadUser(result.validateEmail)
        }
      }
    })
  const initEmail = student.email || ''

  const HandleValidateEmail = async (email) => {
    if (email === '' || initEmail !== '') return
    validateEmail({
      variables: { email }
    })
  }

  const handleTransferRegistration = async () => {
    if (emailData.validateEmail) {
      await updateGroupMember({
        variables: {
          updateGroupAssociatedUsersInput: {
            group_id: groupId,
            student_id: student.id,
            transferId: emailData.validateEmail.id
          }
        }
      })
      await updateRegistration({
        variables: {
          userRegistration: {
            id: userRegistrationId,
            student_id: emailData.validateEmail.id
          }
        },
        onCompleted: () =>
          toast.success('Registration transferred successfully.'),
        refetchQueries: ['GET_GROUPS', 'GET_REGISTRATION_GROUPS']
      })
      onClose()
    }
  }
  return (
    <Formik
      initialValues={{
        email: student.email || '',
        studentId: student.id
      }}
      validationSchema={sendActivationSchema}
      enableReinitialize={true}
      onSubmit={async (values, { setFieldError, setSubmitting }) => {
        let errorFound = false

        if (emailData?.validateEmail) {
          setFieldError('email', 'Email already exists.')
          errorFound = true
        }
        if (errorFound) {
          setSubmitting(false)
          return false
        }
        await sendActivation({
          variables: { ...values }
        })
      }}
    >
      {({ values, handleChange, handleSubmit }) => (
        <Modal
          title={`${student.first_name} ${student.last_name}`}
          open={isOpen}
          content={
            <form>
              <FormField
                label={'Email'}
                name={'email'}
                value={values.email}
                onChange={handleChange}
                onBlur={(e) => HandleValidateEmail(e.target.value)}
              />
              {(emailData?.validateEmail && userRegistrationId) ? (
                <Text>
                  <Text.Small className="mt-3 text-muted">
                    <span className='text-success mr-2'><strong>Existing User Found!</strong></span>Click to transfer event registration{' '}
                    <Button
                      pill
                      size="small"
                      color="white"
                      onClick={(e) => {
                        e.preventDefault()
                        handleTransferRegistration()
                      }}
                    >
                      TRANSFER
                    </Button>
                  </Text.Small>
                </Text>
              ) : (
              <Text.Small className="mt-3 text-muted">
                This will email a temporary password to the user so they can
                login and activate their account.
              </Text.Small>
              )}
            </form>
          }
          onClose={onClose}
          actions={
            <>
              <Button pill size={'sm'} color={'white'}>
                CANCEL
              </Button>
              <Button
                pill
                icon={'mail'}
                size={'sm'}
                color={'primary'}
                onClick={handleSubmit}
                disabled={validating}
              >
                SEND ACTIVATION
              </Button>
            </>
          }
        />
      )}
    </Formik>
  )
}

export default SendActivationEmailModal
