import { useMutation, useQuery } from '@apollo/client'
import { DELETE_PAYMENT } from 'graphql/DELETE_PAYMENT'
import { GET_USER_REGISTRATIONS_BY_IDS } from 'graphql/GET_USER_REGISTRATIONS_BY_IDS'
import { REFUND_PAYMENT } from 'graphql/REFUND_PAYMENT'
import { observer } from 'mobx-react'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'
import DeletePaymentModal from 'modules/payment/modals/DeletePaymentModal'
import RefundPaymentModal from 'modules/payment/modals/RefundPaymentModal'
import React, { FC, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Dropdown, Grid, Icon, Table, Tag } from 'tabler-react'
import { formatMoney } from 'utils/numberFormat'
import { ADD_USER_EVENT } from '../../graphql/ADD_USER_EVENT'
import { GET_ALL_USER_REGISTRATION_EVENTS } from '../../graphql/GET_ALL_USER_REGISTRATION_EVENTS'
import { GET_REGISTRATION_PAYMENTS } from '../../graphql/GET_REGISTRATION_PAYMENTS'
import { UPDATE_PAYMENT_STATUS } from '../../graphql/UPDATE_PAYMENT_STATUS'
import UpdatePayementModal from '../../modules/payment/modals/UpdatePaymentModal'
import { EUserEventsId } from './UserEvents'

type PaymentHistoryProps = {
  userRegId: number
  studentId: number
  remainingBalance: number
  totalPaid: number
  currency?: string
}

const PaymentHistory: FC<PaymentHistoryProps> = ({
  userRegId,
  studentId,
  remainingBalance,
  totalPaid,
  currency
}) => {
  //const { userRegId, studentId }: any = useParams()
  const { isAdmin, isCoachAdmin, isAdminGroup, isStudent } =
    useGetCurrentUserType()
  const [isDeletePaymentModalOpen, setisDeletePaymentModalOpen] =
    useState(false)
  const [isUpdatePaymentStatusModalOpen, setIsUpdatePaymentStatusModalOpen] =
    useState(false)
  const [isStripeRefundModalOpen, setIsStripeRefundModalOpen] = useState(false)
  const [paymentId, setPaymentId] = useState<number | null>(null)
  const [userEvent] = useMutation(ADD_USER_EVENT)
  const [status, setStatus] = useState<string>('')
  const { data, loading } = useQuery(GET_REGISTRATION_PAYMENTS, {
    variables: {
      user_registration_id: Number(userRegId)
    }
  })
  const paymentsHistory = useMemo(() => {
    if (data) {
      return data.getUserRegistrationPayments
    }
    return []
  }, [data?.getUserRegistrationPayments, loading])
  const toggleDeletePaymentModal = () => {
    setisDeletePaymentModalOpen(!isDeletePaymentModalOpen)
  }
  const toggleUpdatePaymentStatusModal = () => {
    setIsUpdatePaymentStatusModalOpen(!isUpdatePaymentStatusModalOpen)
  }
  const toggleStripeRefundModal = () => {
    setIsStripeRefundModalOpen(!isStripeRefundModalOpen)
  }

  const [deletePaymentMutation] = useMutation(DELETE_PAYMENT, {
    onCompleted: () => {
      toggleDeletePaymentModal()
      userEvent({
        variables: {
          userEvent: {
            user_event_type_id: EUserEventsId.pay_delete,
            student_id: studentId,
            status: 'Deleted',
            team_id: 1,
            user_registration_id: Number(userRegId)
          }
        },
        refetchQueries: [
          {
            query: GET_ALL_USER_REGISTRATION_EVENTS,
            variables: {
              filter: {
                userRegistrationId: userRegId
              },
              limit: 20,
              page: 1
            }
          }
        ]
      })

      toast.success('Payment Deleted.')
    }
  })

  const [updatePaymentStatus] = useMutation(UPDATE_PAYMENT_STATUS, {
    onCompleted: () => {
      toggleUpdatePaymentStatusModal()
      userEvent({
        variables: {
          userEvent: {
            user_event_type_id:
              status === 'Refund'
                ? EUserEventsId.pay_refund
                : EUserEventsId.pay_void,
            student_id: studentId,
            status,
            team_id: 1,
            user_registration_id: Number(userRegId)
          }
        },
        refetchQueries: [
          {
            query: GET_ALL_USER_REGISTRATION_EVENTS,
            variables: {
              filter: {
                userRegistrationId: userRegId
              },
              limit: 20,
              page: 1
            }
          }
        ]
      })
      toast.success(`Payment ${status}.`)
    }
  })

  const [refundPaymentMutation] = useMutation(REFUND_PAYMENT, {
    onCompleted: () => {
      toggleStripeRefundModal()
      userEvent({
        variables: {
          userEvent: {
            user_event_type_id: EUserEventsId.pay_refund,
            student_id: studentId,
            status: 'Refunded',
            team_id: 1,
            user_registration_id: Number(userRegId)
          }
        },
        refetchQueries: [
          {
            query: GET_ALL_USER_REGISTRATION_EVENTS,
            variables: {
              filter: {
                userRegistrationId: userRegId
              },
              limit: 20,
              page: 1
            }
          }
        ]
      })
      toast.success('Payment Refunded.')
    }
  })

  const onDeletePayment = async () => {
    await deletePaymentMutation({
      variables: {
        payment_id: paymentId
      },
      refetchQueries: [
        {
          query: GET_USER_REGISTRATIONS_BY_IDS,
          variables: {
            userRegistrationsId: Number(userRegId),
            studentId: Number(studentId)
          }
        }
      ]
    })
  }

  const onUpdatePaymentStatus = async () => {
    await updatePaymentStatus({
      variables: {
        payment_id: paymentId,
        status
      },
      refetchQueries: [
        {
          query: GET_USER_REGISTRATIONS_BY_IDS,
          variables: {
            userRegistrationsId: Number(userRegId),
            studentId: Number(studentId)
          }
        }
      ]
    })
  }

  const onRefundPayment = async () => {
    await refundPaymentMutation({
      variables: {
        payment_id: paymentId
      },
      refetchQueries: [
        {
          query: GET_USER_REGISTRATIONS_BY_IDS,
          variables: {
            userRegistrationsId: Number(userRegId),
            studentId: Number(studentId)
          }
        }
      ]
    })
  }

  return (
    <>
      <Grid.Row>
        <Grid.Col>
          <Table
            responsive
            className="card-table table-vcenter text-nowrap"
          >
            <Table.Header>
              <Table.Row>
                <Table.ColHeader>Amount</Table.ColHeader>
                <Table.ColHeader>Created By</Table.ColHeader>
                <Table.ColHeader>Method</Table.ColHeader>
                <Table.ColHeader></Table.ColHeader>
                <Table.ColHeader>Date</Table.ColHeader>
                <Table.ColHeader></Table.ColHeader>
                <Table.ColHeader></Table.ColHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {paymentsHistory.map((payment) => (
                <React.Fragment key={payment.id}>
                  <Table.Row>
                    <Table.Col>
                      {formatMoney(
                        payment.amount,
                        payment.currency ?? currency
                      )}
                    </Table.Col>
                    <Table.Col>
                      {payment?.coach_first_name
                        ? `${payment?.coach_first_name} ${payment?.coach_last_name}`
                        : `${payment?.student_first_name ?? ''} ${payment?.student_last_name ?? ''}`}
                    </Table.Col>
                    <Table.Col>
                      {payment?.manual_payment}
                      {payment?.card_brand}
                    </Table.Col>
                    <Table.Col>
                      {payment.fees > 0
                        ? '' +
                          formatMoney(
                            payment.fees,
                            payment.currency ?? currency
                          ) +
                          ' fees'
                        : ''}
                    </Table.Col>
                    <Table.Col>
                      {new Date(payment.created_on).toLocaleDateString()}
                    </Table.Col>
                    <Table.Col>
                      <Tag color={payment.status === 'Paid' ? 'success' : ''}>
                        {payment.status}
                      </Tag>
                    </Table.Col>
                    <Table.Col>
                      {!isStudent && payment.processed_by === 'Manual Payment' && (
                        <Dropdown
                          className="btn btn-white text-muted btn-sm pl-0 pr-0 float-right"
                          toggle={false}
                          icon="more-vertical"
                          isNavLink={true}
                          position="bottom-end"
                          arrow={true}
                          arrowPosition="right"
                          itemsObject={
                            isAdmin ||
                            isCoachAdmin ||
                            payment.manual_payment === 'COMP'
                              ? [
                                  {
                                    value: (
                                      <>
                                        <Icon link name="trash" /> Delete
                                      </>
                                    ),
                                    onClick: (e) => {
                                      e.preventDefault()
                                      setPaymentId(Number(payment.id))
                                      toggleDeletePaymentModal()
                                    }
                                  }
                                ]
                              : [
                                  {
                                    value: (
                                      <>
                                        <Icon link name="minus-circle" /> Void
                                      </>
                                    ),
                                    onClick: (e) => {
                                      e.preventDefault()
                                      if (payment.status === 'Void') return
                                      setStatus('Void')
                                      setPaymentId(Number(payment.id))
                                      toggleUpdatePaymentStatusModal()
                                    }
                                  },
                                  {
                                    value: (
                                      <>
                                        <Icon link name="x-circle" /> Refund
                                      </>
                                    ),
                                    onClick: (e) => {
                                      e.preventDefault()
                                      if (payment.status === 'Refund') return
                                      setStatus('Refund')
                                      setPaymentId(Number(payment.id))
                                      toggleUpdatePaymentStatusModal()
                                    }
                                  }
                                ]
                          }
                        />
                      )}
                      {/* HIDING FOR NOW, COACHES CAN REFUND ON THE PAYMENTS LIST */}
                      {/*isAdminGroup && payment.processed_by === 'Stripe' && (
                        <Icon
                          link
                          name="credit-card"
                          onClick={(e) => {
                            e.preventDefault()
                            setPaymentId(Number(payment.id))
                            toggleStripeRefundModal()
                          }}
                        />
                      )*/}
                    </Table.Col>
                  </Table.Row>
                </React.Fragment>
              ))}
            </Table.Body>
          </Table>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row className="mt-5">
        <Grid.Col>
          <p className="mb-0">
            Total Paid:{' '}
            <span className="font-weight-bold">
              {formatMoney(totalPaid, currency)}
            </span>
          </p>
          <p className="mb-0">
            Remaining Balance:{' '}
            <span className="font-weight-bold">
              {formatMoney(remainingBalance, currency)}
            </span>
          </p>
        </Grid.Col>
      </Grid.Row>

      <DeletePaymentModal
        isModalOpen={isDeletePaymentModalOpen}
        onConfirm={onDeletePayment}
        toggleModal={toggleDeletePaymentModal}
      />
      <UpdatePayementModal
        isModalOpen={isUpdatePaymentStatusModalOpen}
        onConfirm={onUpdatePaymentStatus}
        toggleModal={toggleUpdatePaymentStatusModal}
        status={status}
      />
      <RefundPaymentModal
        isModalOpen={isStripeRefundModalOpen}
        onConfirm={onRefundPayment}
        toggleModal={toggleStripeRefundModal}
      />
    </>
  )
}

export default observer(PaymentHistory)
