import Modal from 'components/Modal';
import NewTeamOnboardingForm from 'modules/team/components/forms/NewTeamOnboardingForm';
import React, { useState } from 'react';
import { Button } from 'tabler-react';
import useReactRouter from "use-react-router";

type Props = object

const NewTeamButton = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { history } = useReactRouter();

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Button
        icon="plus"
        color="info"
        onClick={toggleModal}
      />
      <Modal
        open={isModalOpen}
        onClose={toggleModal}
        content={<NewTeamOnboardingForm toggleModal={toggleModal} />}
        title="Team Onboarding"
      />
    </>
  );
};

export default NewTeamButton;
