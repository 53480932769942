import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Formik } from 'formik'
import React, { useEffect, useMemo } from 'react'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { Button, Card, Form, Grid } from 'tabler-react'
import * as Yup from 'yup'
import { ADD_STUDENT_TO_GROUP } from '../../../graphql/ADD_STUDENT_TO_GROUP'
import { GET_CAMP_ROLES } from '../../../graphql/GET_CAMP_ROLES'
import CampParticipantSearchField from '../../../modules/camp-groups/components/forms/CampParticipantSearchField'
import { Types } from '../../../types/graphql'
import Modal from '../../Modal'
import {
  useGetUserIsAdmin,
  useGetUserIsStudent
} from '../../../modules/common/hooks/useGetCurrentUserType'
import { useRootStore } from '../../../hooks'
import StudentSearchField from '../../Students/Forms/StudentSearchField'
import { GET_CAMP_BY_ID } from '../../../graphql/GET_CAMP_BY_ID'
import useGetRegistrationStatus from '../../../modules/team-registration-status/hooks/useGetRegistrationStatus'
import { ADD_CAMP_STUDENT } from '../../../graphql/ADD_CAMP_STUDENT'
import { EUserEvents, EUserEventsId } from '../../User/UserEvents'
import { ADD_USER_EVENT } from '../../../graphql/ADD_USER_EVENT'
import { ADD_STUDENT_TO_TEAM } from '../../../graphql/ADD_STUDENT_TO_TEAM'
import { GET_STUDENT_TEAM } from '../../../graphql/GET_STUDENT_TEAM'

interface AddStudentToRegistrationGroupModalProps {
  group: Types.Group
  isOpen: boolean
  toggle: () => void
  campId: number
  studentId?: number
  registrationId?: number
}

const AddStudentToRegistrationGroupValidationSchema = Yup.object().shape({
  student_id: Yup.number().required("This field is required."),
  role_id: Yup.number().required("This field is required."),
});
const AddStudentToRegistrationGroupModal = ({
  group,
  isOpen,
  toggle,
  campId,
  studentId,
                                              registrationId
}: AddStudentToRegistrationGroupModalProps) => {
  const isStudent = useGetUserIsStudent()
  const isAdmin = useGetUserIsAdmin()
  const { currentUser } = useRootStore()
  const [addStudentToGroup] = useMutation(ADD_STUDENT_TO_GROUP, {
    onCompleted: () => {
      toast.success("User added to group");
      toggle();
    },
    refetchQueries: ["GET_GROUPS", "GET_REGISTRATION_GROUPS"],
  });
  const [selectedStudent, setSelectedStudent] = React.useState(studentId)
  const [showSearch, setShowSearch] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const { data: campData, loading: campLoading } = useQuery(GET_CAMP_BY_ID, {
    variables: {
      campId: Number(campId)
    }
  })

  const camp = useMemo(() => {
    if (!campLoading && campData?.getCampById) return campData.getCampById

    return null
  }, [campLoading, campData])
  const { teamStatusOptions } = useGetRegistrationStatus(camp?.team_id)
  const [addUserEvent] = useMutation(ADD_USER_EVENT)
  const [addStudentToTeam] = useMutation(ADD_STUDENT_TO_TEAM)
  const [getStudentTeam, { data: studentTeamData }] =
    useLazyQuery(GET_STUDENT_TEAM)
  useEffect(() => {
    if (selectedStudent) {
      getStudentTeam({
        variables: {
          student_id: selectedStudent,
          team_id: Number(camp?.team_id)
        }
      })
    }
  }, [selectedStudent])
  const [registerStudent] = useMutation(ADD_CAMP_STUDENT, {
    onCompleted: (result) => {
      addUserEvent({
        variables: {
          userEvent: {
            location_id: camp?.location_id,
            status: EUserEvents.reg_added,
            student_id: selectedStudent,
            team_id: camp?.team_id,
            camp_id: Number(camp?.id),
            coach_id: !isAdmin && !isStudent ? currentUser.id : null,
            user_event_type_id: EUserEventsId.reg_added,
            user_registration_id: result.addCampStudent
          }
        }
      })

      if (!studentTeamData?.getStudentTeam) {
        addStudentToTeam({
          variables: {
            student_id: selectedStudent,
            team_id: Number(camp?.team_id)
          }
        })
      }
    },
    refetchQueries: ['GET_CAMP_BY_ID']
  })

  const [campRoles, setCampRoles] = React.useState([]);
  const { data: campRolesData } = useQuery(GET_CAMP_ROLES, {
    variables: { campId },
  });

  useEffect(() => {
    if (campRolesData?.campRoles) {
      setCampRoles(
        campRolesData.campRoles
          .filter((role) => role.isExists)
          .map((role) => ({
            label: role.name,
            value: role.roleId,
          })),
      );
    }
  }, [campRolesData]);

  const studentSearchOnChange = async (student) => {
    setLoading(true)
    const data = { studentId: null, registrationId: null }
    if (!student) return data

    if (group.members.find((member) => member.id === student.id)) {
      toast.error('Participant is already a member of this group')
      return data
    }
    const studentRegistration = camp?.registered_students.find(
      (reg) => reg.student_id === Number(student.id)
    )
    if (studentRegistration) {
      return {
        studentId: student.id,
        registrationId: studentRegistration.user_registrations_id
      }
    }
    // create empty registration - set status to Pending
    const pendingStatus = teamStatusOptions.find(
      (status) => status.type === 'Pending'
    )
    const registrationData = {
      student_id: Number(student.id),
      camp_id: Number(campId),
      total_fees: Number(camp?.total_fees || 0),
      status: pendingStatus?.name || 'Pending',
      registration_status_id: pendingStatus?.id || null
    }
    const registration = await registerStudent({
      variables: {
        addCampStudentInput: registrationData
      }
    })
    return {
      studentId: student.id,
      registrationId: registration.data.addCampStudent
    }
  }
  if (!camp) return null
  const renderForm = ({
    values,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
  }) => {
    return (
      <Modal
        className={'overflow-visible'}
        open={isOpen}
        onClose={toggle}
        title={
          isStudent
            ? `Join Group: ${currentUser.first_name} ${currentUser.last_name}`
            : 'Add Group Member'
        }
        content={
          <>
            <Card.Body>
              <Grid.Row>
                {!isStudent && (
                  <Grid.Col xs={12} sm={12} md={6} lg={6}>
                    <Form.Group>
                      {!showSearch && !loading && (
                        <>
                          <CampParticipantSearchField
                            options={camp.registered_students}
                            autoFocus={true}
                            onChange={(param) => {
                              if (!param) return

                              if (
                                group.members.find(
                                  (student) => student.id === param.value
                                )
                              ) {
                                toast.error(
                                  'Participant is already a member of this group'
                                )
                                setFieldValue('student_id', null)
                                setFieldValue('registration_id', null)
                                return
                              }
                              setFieldValue('student_id', Number(param.value))
                              setFieldValue(
                                'registration_id',
                                Number(param.user_registrations_id)
                              )
                            }}
                            isDisabled={isStudent}
                            defaultValue={selectedStudent ?? null}
                            campId={campId}
                            status={'Confirmed'}
                          />
                          <Button
                            color={'white'}
                            size={'sm'}
                            className={'text-primary mt-3'}
                            onClick={() => setShowSearch(true)}
                          >
                            ADD SOMEONE ELSE
                          </Button>
                        </>
                      )}

                      {showSearch && (
                        <>
                          <StudentSearchField
                            onChange={async (student) => {
                              if (!student) {
                                toast.warning('Failed to select a student')
                              } else {
                                setSelectedStudent(Number(student.id))
                                const { studentId, registrationId } =
                                  await studentSearchOnChange(student)
                                setFieldValue('student_id', Number(studentId))
                                setFieldValue(
                                  'registration_id',
                                  Number(registrationId)
                                )
                                setShowSearch(false)
                              }
                              setLoading(false)
                            }}
                            exact_match={true}
                            addOnEmptyResult={true}
                          />
                          <Button
                            color={'white'}
                            size={'sm'}
                            className={'mt-3'}
                            onClick={() => setShowSearch(false)}
                          >
                            CANCEL
                          </Button>
                        </>
                      )}

                      <span className="field-error text-danger">
                        {errors.student_id &&
                          touched.student_id &&
                          errors.student_id}
                      </span>
                    </Form.Group>
                  </Grid.Col>
                )}

                <Grid.Col xs={12} sm={12} md={6} lg={6}>
                  <Form.Group>
                    <Select
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 })
                      }}
                      menuPortalTarget={document.body}
                      placeholder="Select Role"
                      options={campRoles}
                      name="role_id"
                      onChange={(option) => {
                        setFieldValue('role_id', option.value)
                      }}
                      value={campRoles.find((i) => i.value === values.role_id)}
                    />
                    <span className="field-error text-danger">
                      {errors.role_id && touched.role_id && errors.role_id}
                    </span>
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </Card.Body>
          </>
        }
        actions={
          <>
            <Button
              pill
              size={'sm'}
              color={'white'}
              className={'float-right'}
              onClick={toggle}
            >
              CANCEL
            </Button>
            <Button
              pill
              size={'sm'}
              color={'primary'}
              className={'float-right'}
              onClick={handleSubmit}
            >
              SUBMIT
            </Button>
          </>
        }
      />
    )
  };
  return (
    <Formik
      initialValues={{
        group_id: group.id,
        team_id: group.team_id,
        role_id: campRoles[0]?.value,
        student_id: studentId ?? null,
        registration_id: registrationId ?? null
      }}
      validationSchema={AddStudentToRegistrationGroupValidationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        addStudentToGroup({
          variables: {
            addGroupAssociatedUsersInput: {
              ...values
            }
          }
        })

        setSubmitting(false)
        resetForm()
      }}
    >
      {(formData) => renderForm(formData)}
    </Formik>
  )
};

export default AddStudentToRegistrationGroupModal;
