import React, { useContext } from 'react'
import { EUserTypes } from '../../User/userTypes'
import { Formik } from 'formik'
import {
  useGetUserIsCoach,
  useGetUserIsStudent
} from '../../../modules/common/hooks/useGetCurrentUserType'
import { useRootStore } from '../../../hooks'
import { useLazyQuery, useMutation } from '@apollo/client'
import { ADD_STUDENT } from '../../../graphql/ADD_STUDENT'
import { GET_EMAIL_VALIDATION } from '../../../graphql/GET_EMAIL_VALIDATION'
import { GET_PHONE_VALIDATION } from '../../../graphql/GET_PHONE_VALIDATION'
import FormField from '../../FormField'
import PhoneInput from 'react-phone-input-2'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { Button, Card, Form, Grid } from 'tabler-react'
import { UserStoreContext } from '../../../contexts/userStoreContext'

const addStudentValidationSchema = Yup.object().shape({
  first_name: Yup.string().required('First Name is required.'),
  last_name: Yup.string().required('Last Name is required.')
})

interface AddStudentFromSearchFormProps {
  first_name: string
  changeEvent?: any
  toggle?: () => void
}

const AddStudentFromSearchForm = ({ first_name, changeEvent, toggle }) => {
  const isCoach = useGetUserIsCoach()
  const isStudent = useGetUserIsStudent()
  const userStore = useContext(UserStoreContext)
  //const { user } = userStore;
  const rootStore = useRootStore()
  const { currentUser, currentCoachTeam } = rootStore

  const [addStudent] = useMutation(ADD_STUDENT)

  const [validateEmail, { data: emailData }] = useLazyQuery(
    GET_EMAIL_VALIDATION,
    {
      fetchPolicy: 'network-only',
      onCompleted: (result) => {
        if (result.validateEmail) {
          userStore.loadUser(result.validateEmail)
        }
      }
    }
  )
  const [validatePhone, { data: phoneData }] = useLazyQuery(
    GET_PHONE_VALIDATION,
    {
      fetchPolicy: 'network-only',
      onCompleted: (result) => {
        if (result.validatePhone) {
          userStore.loadUser(result.validatePhone)
        }
      }
    }
  )

  const HandleValidateEmail = async (email) => {
    if (email === '') return
    validateEmail({
      variables: { email }
    })
  }

  const HandleValidatePhone = async (phone) => {
    if (phone.length <= 1) return
    return validatePhone({
      variables: { phone }
    })
  }

  const RenderForm = ({
    values,
    handleSubmit,
    isSubmitting,
    handleChange,
    setFieldValue,
    errors,
    touched
  }) => (
    <form onSubmit={handleSubmit}>
      <Card className="p-4 bg-light">
        <Grid.Row className="mb-4">
          <Grid.Col width={12}>
            <Form.Group label="">
              <Form.Group label="First name:*">
                <FormField
                  type="text"
                  placeholder="First name"
                  name="first_name"
                  onChange={handleChange}
                  value={values.first_name}
                />
              </Form.Group>
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={12}>
            <Form.Group label="Last name:*">
              <FormField
                type="text"
                placeholder="Last name"
                name="last_name"
                onChange={handleChange}
                value={values.last_name}
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row className="mb-4">
          <Grid.Col width={12} className="pl-3 pr-0">
            <Form.Group label="Phone:">
              <PhoneInput
                inputProps={{
                  className: 'form-control w-100',
                  name: 'phone_number',
                  //  required: true,
                  onChange: (e) => {
                    e.preventDefault()
                    const target = e.target
                    const phone = target.value.split(' ')
                    const countryCode = phone.shift().replace(/\D/g, '')
                    setFieldValue(
                      'phone_number',
                      phone.join('').replace(/\D/g, '')
                    )
                    setFieldValue('phone_country_code', Number(countryCode))
                  },
                  onBlur: async (e) => {
                    e.preventDefault()
                    await HandleValidatePhone(
                      e.target.value.split(' ').join('').replace(/\D/g, '')
                    )
                  }
                }}
                value={`${values.phone_country_code}${values.phone_number}`}
              />
              <span className="field-error text-danger">
                {errors.phone_number && touched.phone_number && (
                  <>
                    {errors.phone_number}{' '}
                    {isCoach && <Link to={'/user-details'}>VIEW USER</Link>}
                  </>
                )}
              </span>
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={12}>
            <Form.Group label="Email:">
              <FormField
                type="email"
                placeholder="Email"
                name="email"
                onChange={handleChange}
                onBlur={(e) => HandleValidateEmail(e.target.value)}
                value={values.email}
              />
              <span className="field-error text-danger">
                {errors.email && touched.email && (
                  <>
                    {!isStudent && <Link to={'/user-details'}>VIEW USER</Link>}
                  </>
                )}
              </span>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col className="pt-2" xs={12} sm={12} lg={12}>
            <Button
              type="submit"
              disabled={isSubmitting}
              pill
              className="btn-gray-dark float-right btn-sm"
            >
              {isSubmitting ? 'Processing...' : 'SUBMIT'}
            </Button>
            {toggle && (
              <Button
                disabled={isSubmitting}
                pill
                className="btn-white float-right btn-sm"
                onClick={toggle}
              >
                CANCEL
              </Button>
            )}
          </Grid.Col>
        </Grid.Row>
      </Card>
    </form>
  )
  return (
    <Formik
      enableReinitialize={true}
      validationSchema={addStudentValidationSchema}
      initialValues={{
        first_name: first_name || '',
        last_name: '',
        email: '',
        phone_number: '',
        phone_country_code: 1
      }}
      onSubmit={async (values, { resetForm, setSubmitting, setFieldError }) => {
        let errorFound = false

        if (emailData?.validateEmail) {
          setFieldError('email', 'Email already exists.')
          errorFound = true
        }

        if (phoneData?.validatePhone) {
          setFieldError('phone_number', 'Phone number already exists.')
          errorFound = true
        }
        if (!values.phone_number) {
          values.phone_country_code = undefined
        }
        if (errorFound) {
          setSubmitting(false)
          return false
        }

        const option = { team_id: undefined }
        if (
          [EUserTypes.coach.toString(), EUserTypes.rigger.toString()].includes(
            currentUser.type
          )
        )
          option.team_id = currentCoachTeam.id
        const newStudent = await addStudent({
          variables: {
            student: {
              ...values,
              ...option
            }
          }
        })
        setSubmitting(false)
        resetForm()
        if (changeEvent) changeEvent(newStudent?.data.addStudent)
      }}
    >
      {(formikData) => RenderForm(formikData)}
    </Formik>
  )
}

export default AddStudentFromSearchForm
