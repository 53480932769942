import React, { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { GET_GROUPS } from '../../../graphql/GET_GROUPS'
import { Grid, StampCard, Text } from 'tabler-react'
import Skeleton from 'react-loading-skeleton'
import Box from '@material-ui/core/Box'

const GroupCountCardSkeleton = () => {
  return (
    <Grid.Col sm={6} lg={3} xs={12} className="pl-0">
      <Box>
        <Box pb={2}>
          <Skeleton height={65} width="100%" />
        </Box>
      </Box>
    </Grid.Col>
  )
}
const GroupCountCard = ({
  campId,
  teamId,
  setGroupFilter
}: {
  campId: number
  teamId: string
  setGroupFilter?: (status: string) => void
}) => {
  const { data, loading } = useQuery(GET_GROUPS, {
    variables: {
      filter: {
        camp_id: Number(campId),
        team_id: teamId,
        order_source: 'discipline'
      }
    }
  })

  const groupList = useMemo(() => {
    if (data) {
      return data.groupList
    }
    return []
  }, [data?.groupList])

  if (loading) return <GroupCountCardSkeleton />

  return (
    <>
      <Grid.Col sm={6} lg={3} xs={12} className="pl-0">
        <span>
          <StampCard
            className="mb-0"
            color="info"
            icon="users"
            header={
              <Text>
                <span
                  onClick={() => setGroupFilter && setGroupFilter('Active')}
                  className={'cursor-pointer'}
                >
                  {
                    groupList.filter((group) => group.status === 'Active')
                      .length
                  }{' '}
                  <small>Groups</small>
                </span>
              </Text>
            }
            footer={
              <Text>
                <span
                  onClick={() => setGroupFilter && setGroupFilter('Archived')}
                  className={'cursor-pointer'}
                >
                  {
                    groupList.filter((group) => group.status === 'Archived')
                      .length
                  }{' '}
                  Archived
                </span>
              </Text>
            }
          />
        </span>
      </Grid.Col>
    </>
  )
}

export default GroupCountCard
