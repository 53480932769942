import { useMutation } from '@apollo/client'
import { observer } from 'mobx-react'
import moment from 'moment/moment'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Avatar, Card, Dropdown, Grid, Icon, List, Text } from 'tabler-react'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'
import { useRootStore } from 'hooks'
import { DELETE_USER_POST } from 'graphql/DELETE_USER_POST'
import { GET_USER_POSTS } from 'graphql/GET_USER_POSTS'
import UserPostEditForm from 'components/User/Forms/UserPostEditForm'
import ReactHtmlParser from 'react-html-parser'
import * as DOMPurify from 'dompurify'
import { ADD_USER_FLAG } from '../../graphql/ADD_USER_FLAG'
import { DELETE_USER_FLAG } from '../../graphql/DELETE_USER_FLAG'
import { EUserFlags } from '../../modules/user-flag/constants'
import UserPostCommentModal from '../../modules/user-post/comments/components/modals/UserPostCommentModal'

interface IUserPostListItemProps {
  userPost: any
}

const UserPostListItem = ({ userPost }: IUserPostListItemProps) => {
  const { currentUser } = useRootStore();
  const userPostClone = { ...userPost };
  const [editting, setEditting] = useState(false);
  const [showCommentForm, setShowCommentForm] = useState(false)

  const isAuthorPost =
    currentUser.id === userPostClone.coach_id ||
    currentUser.student_id === userPostClone.student_id ||
    currentUser.id === userPostClone.student_id
  const { isAdmin, isCoachAdmin, isCoachSuperAdmin, isStudent } =
    useGetCurrentUserType()

  const handleEditLink = (param) => {
    setEditting(param)
  }

  const hasStudentId = isStudent || currentUser.student_id

  const [addLike] = useMutation(ADD_USER_FLAG, {
    onCompleted: () => toast.success('Reaction added!')
  })
  const [removeFlag] = useMutation(DELETE_USER_FLAG, {
    onCompleted: () => toast.success('Reaction removed!')
  })

  const handleLike = async (flagId) => {
    if (isAuthorPost) return
    await addLike({
      variables: {
        userFlag: {
          student_id: isStudent ? currentUser.id : currentUser.student_id,
          user_post_id: userPost.id,
          flag_type_id: flagId
        }
      },
      refetchQueries: ['GET_USER_POSTS']
    })
  }

  const handleRemoveFlag = async (id, flagId) => {
    removeFlag({
      variables: {
        id
      },
      refetchQueries: ['GET_USER_POSTS']
    })
  }

  const author = userPostClone?.coach_first_name
    ? userPostClone?.coach_first_name + ' ' + userPostClone?.coach_last_name
    : userPostClone?.student_first_name + ' ' + userPostClone?.student_last_name

  const [deleteUserPost] = useMutation(DELETE_USER_POST, {
    onCompleted: (result) => {
      if (result.deleteUserPost) {
        toast.success('Post deleted!')
      }
    }
  })

  const deletePost = () => {
    if (window.confirm('Delete post?')) {
      deleteUserPost({
        variables: {
          id: userPost.id
        },
        refetchQueries: ['GET_USER_POSTS']
      })
    }
  };

  const avatar = userPost.coach_avatar || userPost.student_avatar

  return (
    <>
      <div>
        <Card className="p-0 mb-0">
          <Card.Body className="p-3">
            {editting ? (
              <UserPostEditForm
                userPost={userPostClone}
                setEditting={setEditting}
              />
            ) : (
              <>
                <Dropdown
                  className="text-muted pl-0 pr-0 float-right"
                  toggle={false}
                  icon="more-horizontal"
                  isNavLink={true}
                  position="bottom-end"
                  arrow={true}
                  arrowPosition="right"
                  items={
                    <>
                      {(isAdmin ||
                        isAuthorPost ||
                        isCoachSuperAdmin ||
                        [
                          userPostClone.coach_id,
                          userPostClone.student_id
                        ].includes(currentUser.id)) && (
                        <Dropdown.Item
                          className="cursor-pointer"
                          onClick={() => handleEditLink(true)}
                        >
                          <Icon name="edit" /> Edit
                        </Dropdown.Item>
                      )}
                      {(isAdmin ||
                        isAuthorPost ||
                        isCoachAdmin ||
                        isCoachSuperAdmin) && (
                        <Dropdown.Item
                          className="cursor-pointer"
                          onClick={() => deletePost()}
                        >
                          <Icon name="trash" /> Delete
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item
                        className="text-muted cursor-pointer"
                        onClick={() => {
                          if (!userPost.post_reported) {
                            handleLike(EUserFlags.report)
                          } else {
                            handleRemoveFlag(
                              userPost.report_id,
                              EUserFlags.report
                            )
                          }
                        }}
                      >
                        <Icon name="alert-triangle" /> Report Post
                      </Dropdown.Item>
                    </>
                  }
                />
                <Grid.Row>
                  <Avatar
                    size="md"
                    className="mx-2 float-left mb-2 d-block"
                    imageURL={
                      (avatar && `/api/s3/uploads/${avatar}`) ||
                      '//www.gravatar.com/avatar?d=mp'
                    }
                  />
                  <Text>
                    <strong>{author}</strong>
                    <br />
                    <Text.Small className="text-muted">
                      {moment(userPost.created_on).format(
                        'MMM DD, YYYY - h:mm'
                      )}
                      <i>{(userPost.updated_on === userPost.created_on) ? '' : ''}</i>
                    </Text.Small>
                  </Text>
                </Grid.Row>
                <Text
                  className="ws-prewrap textEditor-display d-block pl-2"
                  //  onClick={() => handleEditLink(true)}
                >
                  {ReactHtmlParser(DOMPurify.sanitize(userPost.content))}
                </Text>
              </>
            )}
          </Card.Body>
        </Card>

        {hasStudentId && (
          <List inline className="float-right list-inline-dots mb-0">
            {/*
            <List.Item inline className="text-muted m-1 pb-3 cursor-pointer">
              <span
                onClick={() => {
                  if (!userPost.post_liked) {
                    handleLike(EUserFlags.like)
                  } else {
                    handleRemoveFlag(userPost.liked_id, EUserFlags.like)
                  }
                }}
                className={`${
                  userPost.post_liked ? 'text-primary' : 'text-muted'
                }`}
              >
                <i className={`fe fe-thumbs-up mr-2`} />
                Like
              </span>
            </List.Item>
            <List.Item inline className="text-muted m-1 pb-3 cursor-pointer">
              <span
                onClick={() => {
                  if (!userPost.post_loved) {
                    handleLike(EUserFlags.love)
                  } else {
                    handleRemoveFlag(userPost.loved_id, EUserFlags.love)
                  }
                }}
                className={`${
                  userPost.post_loved ? 'text-primary' : 'text-muted'
                }`}
              >
                <i className={`fe fe-heart mr-2`} />
                Love
              </span>
            </List.Item>
            */}
            {isCoachSuperAdmin && (
              <List.Item inline className="text-muted m-1 pb-3 cursor-pointer">
                <span
                //  onClick={() => setShowCommentForm(true)}
                >
                  <i className="fe fe-bell mr-2 text-muted" />
                </span>
              </List.Item>
            )}
            <List.Item inline className="text-muted m-1 pb-3 cursor-pointer">
              <span onClick={() => setShowCommentForm(true)}>
                <i className="fe fe-message-square mr-2 text-muted" />
                Comment
              </span>
            </List.Item>
          </List>
        )}

        <Text.Small className="float-left text-muted m-1 pb-3">
          {/*
          <i className="fe fe-calendar mr-6 text-muted" />
          {moment(userPost.created_on).format('MMM DD, YYYY - h:mm')}
          {(userPost.updated_on !== userPost.created_on) && ' edited'}
          */}
          <span
            onClick={() => {
              if (!userPost.post_liked) {
                handleLike(EUserFlags.like)
              } else {
                handleRemoveFlag(userPost.liked_id, EUserFlags.like)
              }
            }}
            className={
              'cursor-pointer ml-2 mr-3 ' +
              `${userPost.postMeta.likes ? 'text-primary' : 'text-muted'}`
            }
          >
            <i className="fe fe-thumbs-up mr-1" />
            {userPost.postMeta.likes || 0}
          </span>
          <span
            onClick={() => {
              if (!userPost.post_loved) {
                handleLike(EUserFlags.love)
              } else {
                handleRemoveFlag(userPost.loved_id, EUserFlags.love)
              }
            }}
            className={
              'cursor-pointer mr-3 ' +
              `${userPost.postMeta.loves ? 'text-danger' : 'text-muted'}`
            }
          >
            <i className="fe fe-heart mr-1" />
            {userPost.postMeta.loves || 0}
          </span>
          <span
            className={
              'cursor-pointer ' +
              `${userPost.comments.length > 0 ? 'text-primary' : 'text-muted'}`
            }
            onClick={() => setShowCommentForm(true)}
          >
            <i className="fe fe-message-square mr-1" />
            {userPost.comments.length}
          </span>
        </Text.Small>
      </div>
      <UserPostCommentModal
        isOpen={showCommentForm}
        toggle={() => setShowCommentForm(false)}
        comments={userPost.comments}
        postId={userPost.id}
      />
    </>
  )
};

export default observer(UserPostListItem);
