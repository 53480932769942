import gql from 'graphql-tag'

export const GET_USER_POSTS = gql`
  query GET_USER_POSTS($userPostOptions: UserPostOptions!) {
    userPosts(userPostOptions: $userPostOptions) {
      id
      student_id
      coach_id
      camp_id
      content
      is_public
      created_on
      coach_first_name
      coach_last_name
      coach_avatar
      student_first_name
      student_last_name
      student_avatar
      post_liked
      liked_id
      post_loved
      loved_id
      post_reported
      reported_id
      comments {
        id
        coach_id
        student_id
        student_first_name
        student_last_name
        student_avatar
        content
        user_post_id
        is_public
        created_on
        updated_on
        comment_liked
        comment_loved
        comment_reported
        liked_id
        loved_id
        reported_id
        commentMeta {
          likes
          loves
        }
      }
      postMeta {
        likes
        loves
        reports
      }
    }
  }
`
