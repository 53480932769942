import { useMutation, useQuery } from '@apollo/client'
import {
  ADDITIONAL_EVENT_TYPE_ID,
  SACTION_FEE_TYPE_ID
} from 'components/Students/Forms/StudentRegistrationForm'
import { Formik } from 'formik'
import { CREATE_CAMP_REGISTRATION_OPTION } from 'graphql/CREATE_CAMP_REGISTRATION_OPTION'
import { DELETE_CAMP_REGISTRATION_OPTION } from 'graphql/DELETE_CAMP_REGISTRATION_OPTION'
import { GET_CAMP_REGISTRATION_OPTIONS } from 'graphql/GET_CAMP_REGISTRATION_OPTIONS'
import React, { useMemo } from 'react'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { Button, Card, Form, Grid, Icon, Tag, Text } from 'tabler-react'
import { formatMoney } from 'utils/numberFormat'
import * as Yup from 'yup'
import { CREATE_REGISTRATION_OPTION } from '../../../graphql/CREATE_REGISTRATION_OPTION'
import { GET_CAMP_OPTION_TYPES } from '../../../graphql/GET_CAMP_OPTION_TYPES'
import { UPDATE_REGISTRATION_OPTION } from '../../../graphql/UPDATE_REGISTRATION_OPTION'
import useGetCurrentUserType from '../../../modules/common/hooks/useGetCurrentUserType'
import { Types } from '../../../types/graphql'
import FormField from '../../FormField'
import Modal from '../../Modal'

interface ICalendarEventRegistrationsProp {
  event: Types.Camp
}

const CalendarEventRegistrations = ({
  event,
}: ICalendarEventRegistrationsProp) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { isCoachSuperAdmin } = useGetCurrentUserType();
  const { data, loading } = useQuery(GET_CAMP_REGISTRATION_OPTIONS, {
    variables: {
      campId: Number(event.id),
      teamId: Number(event?.team_id),
    },
  });
  const [selectedOption, setSelectedOption] = React.useState(null);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const [addCampRegistrationOptions] = useMutation(
    CREATE_CAMP_REGISTRATION_OPTION,
    {
      onCompleted: (result) => {
        if (result.addCampRegistrationOptions) {
          toast.success("Registration option added to camp");
        } else {
          toast.error("Something went wrong while trying to add option.");
        }
      },
    },
  );

  const [deleteCampRegistrationOption] = useMutation(
    DELETE_CAMP_REGISTRATION_OPTION,
    {
      onCompleted: (result) => {
        if (result.deleteCampRegistrationOption) {
          toast.success("Registration option deleted");
        } else {
          toast.error("Something went wrong while trying to delete option.");
        }
      },
    },
  );

  const optionToggle = (option, isChecked) => {
    if (isChecked) {
      addCampRegistrationOptions({
        variables: {
          campRegistrationOption: {
            camp_id: Number(event.id),
            registration_option_id: option.id,
          },
        },
        refetchQueries: [
          {
            query: GET_CAMP_REGISTRATION_OPTIONS,
            variables: {
              campId: Number(event.id),
              teamId: Number(event?.team_id),
            },
          },
        ],
      });
    } else {
      if (
        window.confirm(
          "Are you sure you want to delete this option: " + option.name,
        )
      ) {
        deleteCampRegistrationOption({
          variables: {
            campId: Number(event.id),
            registrationOptionId: option.id,
          },
          refetchQueries: [
            {
              query: GET_CAMP_REGISTRATION_OPTIONS,
              variables: {
                campId: Number(event.id),
                teamId: Number(event?.team_id),
              },
            },
          ],
        });
      }
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Registration Options</Card.Title>
        <Card.Options>
          <Button
            outline
            icon={"plus"}
            color={"primary"}
            size={"sm"}
            onClick={() => {
              setSelectedOption(null);
              toggleModal();
            }}
          />
        </Card.Options>
      </Card.Header>
      <Card.Body>
        {!loading &&
          data?.getAvailableCampRegistrationOptions.map((option) => {
            return (
              <Grid.Row key={option.id}>
                <Grid.Col>
                  {option.camp_id && (
                    <>
                      <Tag
                        className={'text-muted float-right cursor-pointer mt-2'}
                        onClick={() => {
                          setSelectedOption(option)
                          toggleModal()
                        }}
                      >
                        {option.option_type_name}
                      </Tag>
                      <span className={'float-right mt-2'}>
                        {option.reg_form === 1 && (
                          <Icon
                            name={'clipboard'}
                            className={'text-primary mr-1'}
                          />
                        )}
                        {option.apply_sanction_fee === 1 && (
                          <Icon
                            name={'dollar-sign'}
                            className={'text-success mr-1'}
                          />
                        )}
                      </span>
                    </>
                  )}
                  <Form.Checkbox
                    name="status"
                    className={'float-left'}
                    label={
                      <>
                        <p className="mb-0">{option.name}</p>
                        <Text.Small className="text-muted">
                          {formatMoney(
                            Number(option.option_value),
                            event.registration_currency
                          )}
                          {' ' + option.fee_type_name}
                        </Text.Small>
                      </>
                    }
                    checked={option.is_exist}
                    onChange={(e) => {
                      optionToggle(option, e.target.checked)
                    }}
                  />
                </Grid.Col>
              </Grid.Row>
            )
          })}
      </Card.Body>
      <Card.Footer className="pl-4">
        <Text.Small className="text-muted">
          <Button
            icon={"clipboard"}
            color={"white"}
            size={"sm"}
            className={"text-primary mr-2"}
            disabled
          >
            <span className="text-muted">On Registration Form</span>
          </Button>
          {/*isCoachSuperAdmin && (
          <Button
            icon={"dollar-sign"}
            color={"white"}
            size={"sm"}
            className={"text-success"}
            disabled
          >
            <span className="text-muted">Sanction Fee Applies</span>
          </Button>
          )*/}
        </Text.Small>
      </Card.Footer>
      <AddCampOptionModal
        campId={event.id}
        isOpen={isOpen}
        toggle={toggleModal}
        campOption={selectedOption}
        eventTypeId={event.event_type_id}
        hasSanctionFee={
          !!data?.getAvailableCampRegistrationOptions.find(
            (option) =>
              option.option_type_id === SACTION_FEE_TYPE_ID && option.is_exist,
          )
        }
        hasAdditionalEvent={
          !!data?.getAvailableCampRegistrationOptions.find(
            (option) =>
              option.option_type_id === ADDITIONAL_EVENT_TYPE_ID &&
              option.is_exist,
          )
        }
      />
    </Card>
  );
};

export default CalendarEventRegistrations;

const AddCampOptionValidationSchema = Yup.object().shape({
  name: Yup.string().required("This field is required."),
  description: Yup.string().required("This field is required."),
  option_type_id: Yup.string().required("This field is required."),
  option_value: Yup.string().required("This field is required."),
});
const AddCampOptionModal = ({
  campId,
  isOpen,
  toggle,
  campOption,
  eventTypeId,
  hasSanctionFee,
  hasAdditionalEvent,
}) => {
  const { data: campOptionTypes } = useQuery(GET_CAMP_OPTION_TYPES);
  const { isAdmin, isCoachSuperAdmin } = useGetCurrentUserType();
//  const boogieOptions = [3];
  const eventOptions = [2, 3];
  const defaultOptions = [2, 3];
  const competitionOptions = [3, 5, 7, 8, 9, 10]; // For competition only show 'entry fee'(5) 'add ons'(3) option types Sanction Fee(10), Training Jumps (8), Training Time (9), Additional Event(7)
  const registration_option_types = useMemo(() => {
    let optionList =
      campOptionTypes?.getRegistrationOptionTypes.filter((option) =>
        eventTypeId === "2"
          ? competitionOptions.includes(Number(option.id))
          : eventTypeId === "5"
          ? eventOptions.includes(Number(option.id))
          : defaultOptions.includes(Number(option.id)),
      ) || [];

    if (hasSanctionFee) {
      optionList = optionList.filter(
        (option) => option.id !== SACTION_FEE_TYPE_ID,
      );
    }
    if (hasAdditionalEvent) {
      optionList = optionList.filter(
        (option) => option.id !== ADDITIONAL_EVENT_TYPE_ID,
      );
    }

    return optionList;
  }, [campOptionTypes, eventTypeId, hasSanctionFee, hasAdditionalEvent]);

  const feeTypes =
    campOptionTypes?.feeTypes.map((option) => ({
      label: option.name,
      value: option.id,
    })) || [];

  const [createNewRegistrationOption] = useMutation(
    CREATE_REGISTRATION_OPTION,
    {
      onCompleted: () => toast.success(`Registration option saved.`),
      refetchQueries: ["GET_CAMP_REGISTRATION_OPTIONS"],
    },
  );
  const [updateRegistrationOption] = useMutation(UPDATE_REGISTRATION_OPTION, {
    onCompleted: () => toast.success(`Registration option saved.`),
    refetchQueries: ["GET_CAMP_REGISTRATION_OPTIONS"],
  });

  return (
    <Modal
      open={isOpen}
      onClose={toggle}
      title="Add Registration Option"
      content={
        <Formik
          initialValues={{
            id: campOption?.id ?? undefined,
            name: campOption?.name ?? "",
            description: campOption?.description ?? "",
            option_type_id: campOption?.option_type_id ?? "",
            option_value: campOption?.option_value ?? "",
            camp_id: campOption?.camp_id ?? campId,
            reg_form: campOption?.reg_form ?? 0,
            apply_sanction_fee: campOption?.apply_sanction_fee ?? 0,
            fee_type_id: campOption?.fee_type_id ?? "",
          }}
          validationSchema={AddCampOptionValidationSchema}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            if (values.id) {
              updateRegistrationOption({
                variables: {
                  id: values.id,
                  name: values.name,
                  description: values.description,
                  option_value: String(values.option_value),
                  option_type_id: values.option_type_id,
                  fee_type_id: values.fee_type_id,
                  camp_id: campId,
                  reg_form: values.reg_form,
                  apply_sanction_fee: values.apply_sanction_fee,
                },
              });
            } else {
              await createNewRegistrationOption({
                variables: {
                  name: values.name,
                  description: values.description,
                  option_value: String(values.option_value),
                  option_type_id: values.option_type_id,
                  fee_type_id: values.fee_type_id,
                  camp_id: campId,
                  reg_form: values.reg_form,
                  apply_sanction_fee: values.apply_sanction_fee,
                },
              });
            }

            resetForm();
            setSubmitting(false);
            toggle();
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            errors,
            touched,
          }) => (
            <Form id="registrationOptionForm" onSubmit={handleSubmit}>
              <Grid.Row>
                <Grid.Col>
                  <FormField
                    name="name"
                    label="Name*"
                    placeholder="Name"
                    onChange={handleChange}
                    value={values.name}
                  />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <FormField
                    multiline="true"
                    name="description"
                    label="Description*"
                    placeholder="Description"
                    onChange={handleChange}
                    value={values.description}
                  />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <Form.Group>
                    <Form.Label>Option Type*</Form.Label>
                    <Select
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      backspaceRemovesValue={true}
                      escapeClearsValue={true}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isClearable={true}
                      name="option_type_id"
                      options={registration_option_types}
                      onChange={(params) => {
                        setFieldValue("option_type_id", params.id);
                        if (params.id !== "5") {
                          setFieldValue("reg_form", 0);
                          setFieldValue("apply_sanction_fee", 0);
                        }
                      }}
                      value={
                        values.option_type_id &&
                        registration_option_types.find(
                          (regOptType) =>
                            regOptType.id === values.option_type_id,
                        )
                      }
                    />
                    <span className="field-error text-danger">
                      {errors.option_type_id &&
                        touched.option_type_id &&
                        errors.option_type_id}
                    </span>
                  </Form.Group>
                </Grid.Col>
                <Grid.Col>
                  <FormField
                    name="option_value"
                    label="Option Value*"
                    placeholder="$0.00"
                    onChange={handleChange}
                    type="number"
                    value={values.option_value}
                  />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <Form.Group>
                    <Form.Label>Fee Type*</Form.Label>
                    <Select
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      backspaceRemovesValue={true}
                      escapeClearsValue={true}
                      isClearable={true}
                      name="fee_type_id"
                      options={feeTypes}
                      onChange={(params) => {
                        setFieldValue("fee_type_id", params.value);
                      }}
                      value={
                        values.fee_type_id &&
                        feeTypes.find(
                          (feeType) => feeType.value === values.fee_type_id,
                        )
                      }
                    />
                    <span className="field-error text-danger">
                      {errors.fee_type_id &&
                        touched.fee_type_id &&
                        errors.fee_type_id}
                    </span>
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              {isCoachSuperAdmin && values.option_type_id === "5" && (
                // Only show the expires and quanity fields when option type is 'entry fee'
                // Only show the expires and quanity for competitions
              <Grid.Row>
                <Grid.Col width={6}>
                  <FormField
                    name="expires"
                    label="Expires"
                    placeholder=""
                  //  onChange={handleChange}
                    type="date"
                  //  value={values.expires}
                    disabled
                  />
                  <Text.Small className="text-muted">Date this option will no longer be available to select</Text.Small>
                </Grid.Col>
                <Grid.Col xs={6} md={3}>
                  <FormField
                    name="quanity"
                    label="Quanity"
                    placeholder=""
                  //  onChange={handleChange}
                    type="number"
                 //   value={values.quanity}
                    disabled
                  />
                  <Text.Small className="text-muted">Default quanity added to each registration</Text.Small>
                </Grid.Col>
              </Grid.Row>
              )}
              <Grid.Row>
                {
                  // Only show the registration form and apply sanction fee options when option type is 'entry fee, add on, training jumps, training time'
                  (values.option_type_id === "3" ||
                  values.option_type_id === "5" ||
                  values.option_type_id === "8" ||
                  values.option_type_id === "9") && (
                    <>
                      <Grid.Col>
                        <Form.Group label={"Registration Form"}>
                          <Form.Switch
                            name="reg_form"
                            checked={!!values.reg_form}
                            onChange={(e) => {
                              setFieldValue(
                                "reg_form",
                                e.target.checked ? 1 : 0,
                              );
                            }}
                          />
                        </Form.Group>
                      </Grid.Col>
                      {(isAdmin || isCoachSuperAdmin) && (
                        <Grid.Col>
                          <Form.Group label={"Apply Sanction Fee"}>
                            <Form.Switch
                              name="apply_sanction_fee"
                              checked={!!values.apply_sanction_fee}
                              onChange={(e) => {
                                setFieldValue(
                                  "apply_sanction_fee",
                                  e.target.checked ? 1 : 0,
                                );
                              }}
                            />
                          </Form.Group>
                        </Grid.Col>
                      )}
                    </>
                  )
                }
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <Button.List align="right">
                    <Button
                      pill
                      color="white"
                      size="sm"
                      onClick={(e) => {
                        e.preventDefault();
                        toggle();
                      }}
                    >
                      CANCEL
                    </Button>
                    <Button
                      pill
                      type="submit"
                      form="registrationOptionForm"
                      className="btn btn-primary"
                      color="gray-dark"
                      size="sm"
                    >
                      SAVE
                    </Button>
                  </Button.List>
                </Grid.Col>
              </Grid.Row>
            </Form>
          )}
        </Formik>
      }
    />
  );
};
