import React, { useContext, useMemo } from 'react'
import UserCardDropdown from '../../User/UserCardDropdown'
import { useRootStore } from '../../../hooks'
import useGetCurrentUserType from '../../../modules/common/hooks/useGetCurrentUserType'
import { useMutation } from '@apollo/client'
import { UPDATE_STUDENT_MUTATION } from '../../../graphql/UPDATE_STUDENT_MUTATION'
import { toast } from 'react-toastify'
import { UPDATE_USER_MUTATION } from '../../../graphql/UPDATE_USER_MUTATION'
import { applySnapshot } from 'mobx-state-tree'
import { Avatar, Dropdown } from 'tabler-react'
import { UserStoreContext } from '../../../contexts/userStoreContext'
import useReactRouter from 'use-react-router'

interface AvatarUploadProps {
  profileAvatar: string
  refetch: any
  studentId: number
}

const AvatarUpload = ({
  profileAvatar,
  refetch,
  studentId
}: AvatarUploadProps) => {
  const userStore = useContext(UserStoreContext)
  const { user } = userStore
  const { location } = useReactRouter()
  const rootStore = useRootStore()
  const { isAdmin, isCoach, isStudent } = useGetCurrentUserType()
  const { currentUser } = rootStore
  const { id, type } = currentUser
  const adminToUpdateStudent: boolean =
    isAdmin && location.pathname === '/user-details'
  const canUploadAvatar = useMemo(() => {
    if (
      !isAdmin &&
      (!profileAvatar && location.pathname !== '/user/profile') &&
      location.pathname === '/user/settings'
    ) {
      return true
    }
    if (!profileAvatar && location.pathname === '/user-details') {
      return true
    }
    if (isStudent) {
      return true
    }
    return false
  }, [location.pathname, isAdmin, isStudent, profileAvatar])

  const [updateStudent] = useMutation(UPDATE_STUDENT_MUTATION, {
    onCompleted: () => toast.success('Avatar Updated.')
  })

  const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
    onCompleted: () => toast.success('Avatar Updated.')
  })

  const onError = (error) => {
    toast.error(error)
    rootStore.setLoading(false)
  }

  const onFinished = (response: { fileKey: string }) => {
    updateAvatar(response.fileKey)
    rootStore.setLoading(false)
  }

  const updateAvatar = async (fileKey: string | null) => {
    if (isCoach && location.pathname.includes('/user/calendar')) {
      await updateStudent({
        variables: {
          student: {
            id: studentId,
            profile_avatar: fileKey
          }
        }
      })
      if (location.pathname.includes('/user/calendar')) {
        refetch()
      }
    } else if (isStudent || adminToUpdateStudent) {
      await updateStudent({
        variables: {
          student: {
            id: adminToUpdateStudent ? user.id : id,
            profile_avatar: fileKey
          }
        }
      })
      if (refetch) {
        refetch()
      }
    } else {
      await updateUser({
        variables: {
          user: {
            id,
            profile_avatar: fileKey,
            type
          }
        }
      })
    }

    if (adminToUpdateStudent) {
      applySnapshot(user, {
        ...user,
        profile_avatar: fileKey
      })
    } else {
      applySnapshot(currentUser, {
        ...currentUser,
        profile_avatar: fileKey
      })
    }
  }
  return (
    <div>
      {
        // Only show options (Upload, Create, and Remove) on settings or current user type is admin
        canUploadAvatar ? (
          <Dropdown
            className="cursor-pointer avatar-dropdown"
            icon="camera"
            items={
              <UserCardDropdown
                rootStore={rootStore}
                imageName={profileAvatar}
                onErrorHandler={onError}
                onFinishedHandler={onFinished}
                callBack={updateAvatar}
              />
            }
            toggle={false}
            triggerContent={
              <Avatar
                className="avatar"
                imageURL={
                  (profileAvatar && `/api/s3/uploads/${profileAvatar}`) ||
                  '//www.gravatar.com/avatar?d=mp'
                }
                size="xxl"
              />
            }
          />
        ) : (
          <Avatar
            className="avatar"
            imageURL={
              (profileAvatar && `/api/s3/uploads/${profileAvatar}`) ||
              '//www.gravatar.com/avatar?d=mp'
            }
            size="xxl"
          />
        )
      }
    </div>
  )
}

export default AvatarUpload
