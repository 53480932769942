import { useMutation, useQuery } from '@apollo/client'
import { Formik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import ReactS3Uploader from 'react-s3-uploader'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { Button, Form, Grid, Icon, Tag } from 'tabler-react'
import { ADD_GROUP } from '../../../graphql/ADD_GROUP'
import { EDIT_GROUP } from '../../../graphql/EDIT_GROUP'
import { GET_CAMP_DISCIPLINES } from '../../../graphql/GET_CAMP_DISCIPLINES'
import { useRootStore } from '../../../hooks'
import { GET_CAMP_ACTIVE_REGISTRATION_OPTIONS } from '../../../graphql/GET_CAMP_ACTIVE_REGISTRATION_OPTIONS'
import { formatMoney } from '../../../utils/numberFormat'
import { ADD_REGISTRATION_GROUP_OPTION } from '../../../graphql/ADD_REGISTRATION_GROUP_OPTION'
import { GET_REGISTRATION_GROUPS } from '../../../graphql/GET_REGISTRATION_GROUPS'
import { EDIT_REGISTRATION_GROUP_OPTION } from '../../../graphql/EDIT_REGISTRATION_GROUP_OPTION'
import { DELETE_REGISTRATION_GROUP_OPTION } from '../../../graphql/DELETE_REGISTRATION_GROUP_OPTION'
import { GET_GROUP } from '../../../graphql/GET_GROUP'
import debounce from 'lodash.debounce'

const GroupOptionsItem = ({
  option,
  loading,
  handleOptionsToggle,
  handleUpdateQuantity,
  groupOptions,
  groupId
}) => {
  const optionItem = groupOptions.find(
    (opt) => opt.registration_option_id === Number(option.id)
  )
  const [addGroupOption] = useMutation(ADD_REGISTRATION_GROUP_OPTION, {
    refetchQueries: ['GET_GROUPS', 'GET_REGISTRATION_GROUPS', 'GET_GROUP']
  })
  const [editGroupOption] = useMutation(EDIT_REGISTRATION_GROUP_OPTION, {
    refetchQueries: ['GET_GROUPS', 'GET_REGISTRATION_GROUPS', 'GET_GROUP']
  })
  const [removeGroupOption] = useMutation(DELETE_REGISTRATION_GROUP_OPTION, {
    refetchQueries: ['GET_GROUPS', 'GET_REGISTRATION_GROUPS', 'GET_GROUP']
  })
  const [quantity, setQuantity] = useState(optionItem?.quantity || 0)
  const quantityChangeHandler = debounce(async (value) => {
    setQuantity(value || '')
    if (!value) return

    const updatedOption = {
      registration_option_id: optionItem.registration_option_id,
      quantity: Number(value)
    }
    if (!groupId) handleUpdateQuantity(updatedOption)
    else
      await editGroupOption({
        variables: { groupOption: { ...updatedOption, id: optionItem.id } }
      })
  })

  useEffect(() => {
    setQuantity(optionItem?.quantity || '')
  }, [optionItem])

  return (
    <Grid.Row key={option.id}>
      <Grid.Col>
        <Form.Switch
          name="status"
          className="mb-2"
          label={
            <>
              <span className="mb-0">
                <strong>{option.name}</strong>
              </span>
              <span className="d-block">
                {formatMoney(Number(option.option_value))}{' '}
                <span className="text-muted">
                  {option.fee_type_name.toLowerCase()}
                </span>
                <Tag className="ml-3 small">
                  {option.option_type_name.toUpperCase()}
                </Tag>
              </span>
            </>
          }
          checked={
            !!groupOptions.find(
              (opt) => opt.registration_option_id === Number(option.id)
            )
          }
          value={Number(option.id)}
          onChange={(e) => {
            if (!groupId) {
              handleOptionsToggle(e.target.checked, option)
            } else {
              if (e.target.checked) {
                addGroupOption({
                  variables: {
                    groupOption: {
                      group_id: groupId,
                      registration_option_id: option.id,
                      quantity: 1
                    }
                  }
                })
              } else {
                removeGroupOption({ variables: { id: optionItem.id } })
              }
            }
          }}
        />
        {quantity &&
        <input
          className={'w-7 p-1 float-right text-center mt-2'}
          type={'number'}
          disabled={
            !groupOptions.find(
              (opt) => opt.registration_option_id === Number(option.id)
            )
          }
          value={quantity}
          onChange={(e) => {
            quantityChangeHandler(e.target.value)
           }}
        />
        }
      </Grid.Col>
    </Grid.Row>
  )
}

const GroupOptions = ({
  filteredOptions,
  loading,
  handleOptionsToggle,
  handleUpdateQuantity,
  groupOptions,
  groupId
}) => {
  return (
    <Form.Group label="Group Options:">
      {!loading &&
        filteredOptions.map((option) => {
          return (
            <GroupOptionsItem
              key={option.id}
              option={option}
              loading={loading}
              handleOptionsToggle={handleOptionsToggle}
              handleUpdateQuantity={handleUpdateQuantity}
              groupOptions={groupOptions}
              groupId={groupId}
            />
          )
        })}
    </Form.Group>
  )
}

interface RegistrationGroupFormProps {
  toggle?: () => void
  group?: any
  campId?: number
}

const RegistrationGroupForm = ({
  toggle,
  group,
  campId
}: RegistrationGroupFormProps) => {
  const { data: groupResult } = useQuery(GET_GROUP, {
    variables: { id: group?.id },
    skip: !group?.id
  })
  const groupData = groupResult?.group || null
  const rootStore = useRootStore()

  const [addGroup] = useMutation(ADD_GROUP, {
    onCompleted: () => {
      toast.success('Group added successfully!')
    }
  })

  const [editGroup] = useMutation(EDIT_GROUP, {
    onCompleted: () => {
      toast.success('Group updated')
    }
  })

  const { data: disciplinesData, loading: disciplineLoading } = useQuery(
    GET_CAMP_DISCIPLINES,
    {
      variables: {
        campId
      }
    }
  )

  const { data: campOptionsData, loading } = useQuery(
    GET_CAMP_ACTIVE_REGISTRATION_OPTIONS,
    {
      variables: {
        campId: campId
      },
      fetchPolicy: 'network-only'
    }
  )

  const campRegistrationOptions = useMemo(() => {
    if (!loading && campOptionsData?.getCampRegistrationOptions) {
      return campOptionsData?.getCampRegistrationOptions.filter(
        (option) => option.is_exist //&& option.reg_form // && option.fee_type_id === '8' commented out to show all options to group modal
      )
    }
    return []
  }, [campOptionsData, loading])

  const disciplineOptions = useMemo(() => {
    if (disciplineLoading) return []
    return disciplinesData?.campDisciplines
      .filter((discipline) => discipline.isExists)
      .map((discipline) => ({
        label: discipline.name,
        value: discipline.disciplineId
      }))
  }, [disciplinesData, disciplineLoading])

  const onError = (error) => {
    toast.error(error)
    rootStore.setLoading(false)
  }

  const renderForm = ({ values, setFieldValue, handleSubmit }) => {
    return (
      <div>
        <Grid.Row>
          <Grid.Col>
            <Form.Group label="Name">
              <Form.Input
                name="name"
                value={values.name}
                onChange={(e) => setFieldValue('name', e.target.value)}
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <Form.Group label="Discipline">
              <Select
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                options={disciplineOptions}
                name={'discipline_id'}
                onChange={(option) => {
                  setFieldValue('discipline_id', Number(option.value))
                }}
                value={disciplineOptions.find(
                  (i) => Number(i.value) === Number(values.discipline_id)
                )}
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col className="p-3 m-3 px-5 pt-5 border bg-light">
            <GroupOptions
              filteredOptions={campRegistrationOptions}
              groupOptions={values.group_options}
              loading={loading}
              handleOptionsToggle={(checked, option) => {
                const optionData = {
                  registration_option_id: Number(option.id),
                  quantity: 1
                }
                setFieldValue(
                  'group_options',
                  checked
                    ? [...values.group_options, optionData]
                    : values.group_options.filter(
                        (group_option) =>
                          group_option.registration_option_id !==
                          Number(option.id)
                      )
                )
              }}
              handleUpdateQuantity={(option) => {
                setFieldValue('group_options', [
                  ...values.group_options.filter(
                    (group_option) =>
                      group_option.registration_option_id !==
                      Number(option.registration_option_id)
                  ),
                  option
                ])
              }}
              groupId={group.id}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <Form.Group label="Image">
              {rootStore.loading ? (
                <Button
                  loading
                  color="secondary"
                  className="border-0 p-1 shadow-none"
                  block
                >
                  Loading...
                </Button>
              ) : (
                <label
                  htmlFor="s3-upload"
                  className="dropdown-item cursor-pointer mb-0"
                >
                  {values.image ? 'Change' : <Icon name={'image'} />}
                </label>
              )}
              <ReactS3Uploader
                accept="image/*"
                autoUpload={true}
                className="d-none"
                contentDisposition="auto"
                id="s3-upload"
                signingUrl="/api/s3/sign"
                onError={onError}
                onFinish={(response) => {
                  setFieldValue('image', response.fileKey)
                  rootStore.setLoading(false)
                }}
                onProgress={() => rootStore.setLoading(true)}
                uploadRequestHeaders={{
                  'x-amz-acl': 'private'
                }}
              />
              {values.image && (
                <img
                  src={`/api/s3/uploads/${values.image}`}
                  alt={'group logo'}
                />
              )}
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <Button
              className={'float-right mr-2'}
              pill
              color={'gray-dark'}
              size={'small'}
              onClick={handleSubmit}
              disabled={rootStore.loading}
            >
              SUBMIT
            </Button>
            <Button
              className={'float-right mr-2'}
              pill
              color={'white'}
              size={'small'}
              onClick={() => toggle && toggle()}
            >
              CANCEL
            </Button>
          </Grid.Col>
        </Grid.Row>
      </div>
    )
  }
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: group?.id ?? undefined,
        name: group?.name ?? '',
        team_id: group?.team_id ?? null,
        discipline_id: group?.discipline_id ?? null,
        image: group?.image ?? '',
        user_registration_id: group?.user_registration_id ?? null,
        status: group?.status ?? 'Active',
        camp_id: group?.camp_id ?? campId,
        group_options:
          groupData && groupData?.group_options ? groupData?.group_options : []
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        if (!values.id) {
          await addGroup({
            variables: { addGroupInput: values },
            refetchQueries: ['GET_GROUPS', 'GET_REGISTRATION_GROUPS']
          })
        } else {
          const { group_options, ...rest } = values
          await editGroup({
            variables: { updateGroupInput: rest },
            refetchQueries: ['GET_GROUPS', 'GET_REGISTRATION_GROUPS']
          })
        }
        setSubmitting(false)
        resetForm()
        if (toggle) {
          toggle()
        }
      }}
    >
      {(formData) => renderForm(formData)}
    </Formik>
  )
}

export default RegistrationGroupForm;
